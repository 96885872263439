import { FormControlPipe } from './../../shared/pipes/form-control.pipe';
import { TextEditorWrapperComponent } from './../../shared/components/text-editor/text-editor-wrapper/text-editor-wrapper.component';
import { Mode, ModeSwitchComponent } from './mode-switch/mode-switch.component';
import { RequestMessagesTemplatingService } from './../request-messages-templating/request-messages-templating.service';
import { ColfService } from 'src/app/caregiver/colf/colf-service/colf-service.service';
import { PhoneNumbersObj } from './../../shared/models/caregivers-phone-object.models';
import { environment } from './../../../environments/environment';
import { WhatsappBody } from './../smartphone-container/smartphone-container.component';
import { RouterStateManagerService } from './../../shared/services/router-state-manager/router-state-manager.service';
import { BabySitterService } from '../../caregiver/babysitter/baby-sitter-service/baby-sitter-service.service';
import { Request, ModalService, MailTemplateType, MailTemplate, CustomerType } from 'npx-family-happy-common';
import { UntypedFormGroup, UntypedFormControl, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RequestService } from '../request-service/request.service';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { mergeMap, Subject, takeUntil, Observable, switchMap, of } from 'rxjs';
import { Message } from 'src/app/shared/models/message.model';
import { MessagesHistoryService } from 'src/app/shared/services/messages-history-service/messages-history.service';
import { BulletinBoardService } from 'src/app/shared/services/bulletin-board-service/bulletin-board.service';
import { User } from 'src/app/shared/models/user.model';
import { PetsitterService } from 'src/app/caregiver/petsitter/petsitter-service/petsitter-service.service';
import { NurseService } from 'src/app/caregiver/nurse/nurse-service/nurse-service.service';
import { CaregiverAttachment } from 'src/app/shared/models/caregiver-attachment.model';
import { CommonModule } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { WhatsappMessageComposerComponent } from '../whatsapp-message-composer/whatsapp-message-composer.component';
import { FhDatePipe } from 'src/app/shared/pipes/fh-date.pipe';
import { QuillModule } from 'ngx-quill';
import { ScheduledAppointment, ScheduledAppointmentType } from 'src/app/shared/models/scheduled-appointment.model';
import { ScheduledAppointmentService } from 'src/app/shared/services/scheduled-appointment-service/scheduled-appointment.service';
import { CaregiverSelectionService } from '../caregiver-scoreboard/caregiver-selection/caregiver-selection-service/caregiver-selection.service';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import Quill from 'quill';
import HorizontalRuleBlot from 'src/app/shared/components/text-editor/custom/horizontal-separator.blots';
import { UserStorageService } from 'src/app/authentication/user-storage/user-storage.service';
import * as juice from 'juice'; // Importa Juice


// Editor toolbar custom buttons configuration
Quill.register({
  'formats/hr': HorizontalRuleBlot
});
const icons: any = Quill.import('ui/icons');
icons['hr'] = '<svg viewBox="0 0 18 18"><line class="ql-stroke" x1="3" x2="15" y1="9" y2="9"></line></svg>';

@Component({
  selector: 'app-request-messages',
  templateUrl: './request-messages.component.html',
  styleUrls: ['./request-messages.component.scss'],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, MatExpansionModule, FormsModule, WhatsappMessageComposerComponent, FhDatePipe, QuillModule, NgbTooltipModule, ModeSwitchComponent,
    TextEditorWrapperComponent, FormControlPipe]
})
export class RequestMessagesComponent implements OnInit, OnDestroy {

  @Input() requestID!: number;

  Mode = Mode; // expose enum to the template
  request!: Request;
  paymentURL = ''
  attachments: CaregiverAttachment[] = [];
  phoneNumbers: string = '';
  originalPhoneNumbers: string = '';
  phoneNumbersArray: Array<PhoneNumbersObj> = []
  temporaryTemplate: MailTemplate[] = []
  processedTemplate: MailTemplate[] = []
  requestMessages!: Message[];
  updateAd: boolean = false;
  step = -1;
  sections = [
    {
      id: 0,
      title: 'WhatsApp',
      visible: false,
    },
    {
      id: 1,
      title: 'Email Tate',
      visible: false,
      subject: 'tate'
    },
    {
      id: 2,
      title: 'Email Preventivo',
      visible: false,
      subject: 'preventivo'
    },
    {
      id: 3,
      title: 'Email invio profili',
      visible: false,
      subject: 'esito'
    },
    {
      id: 4,
      title: 'Email invio numero di telefono',
      visible: false,
      subject: 'contatti'
    },
  ];
  messages: UntypedFormGroup;

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Inserisci qui il tuo testo...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };
  whatsappData!: WhatsappBody;
  private unsubscribe = new Subject<void>();
  private target$: any
  range = new UntypedFormControl('Province')
  targets = null

  scheduledAppointment?: ScheduledAppointment;
  appointmentDate = '';

  //check title
  oldTitle!:string

  //DICTIONARY FILTERS
  translatedFiltersObj!: any;
  filtersObj!: any;

  translations!: any
  //variable to set advanced targets search with filters
  isAdvancedSearch = false


  constructor(
    private requestService: RequestService,
    private babysitterService: BabySitterService,
    private modalService: ModalService,
    private stateManager: RouterStateManagerService,
    private messagesHistoryService: MessagesHistoryService,
    private boardService: BulletinBoardService,
    private petsitterService: PetsitterService,
    private nurseService: NurseService,
    private messageTemplatingService: RequestMessagesTemplatingService,
    private scheduledAppointmentService: ScheduledAppointmentService,
    private userStorageService: UserStorageService,
    private colfService: ColfService,
    private caregiverSelectionService: CaregiverSelectionService
  ) {
    this.messages = new UntypedFormGroup({
      tate: new UntypedFormControl(''),
      1: new UntypedFormControl(''),
      preventivo: new UntypedFormControl(''),
      2: new UntypedFormControl(''),
      esito: new UntypedFormControl(''),
      3: new UntypedFormControl(''),
      contatti: new UntypedFormControl(''),
      4: new UntypedFormControl(''),
    });

    // Ask caregiver update on messages page load
    this.messageTemplatingService.askCaregiverUpdate();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnInit(): void {

    this.stateManager.getRequestState()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((state) => {
        if (state.request) {
          this.request = state.request;
          this.oldTitle = state.request.operationalInfo.adTitle
          this.whatsappData = this.requestService.getWhatsappData();
        }
        if (!state.scheduledAppointment) {
          this.scheduledAppointmentService.getScheduledAppointmentsByEntityLinked(`${this.requestID}`).subscribe((appointments) => {
            if (appointments.length === 1) {
              this.scheduledAppointment = appointments[0];
              this.appointmentDate = this.scheduledAppointment.timestamp;
            }
          })
        } else {
          this.scheduledAppointment = state.scheduledAppointment;
          this.appointmentDate = this.scheduledAppointment.timestamp;
        }
        if (state.step !== 3) {
          this.ngOnDestroy();
        }
      })
    this.messageTemplatingService.getCaregiverAttachments()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((attachments) => {
        this.attachments = attachments;
      });
    this.messageTemplatingService.getCaregiverPhoneNumbers()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((phoneNumbers) => {
        let phoneNumber = ''
        phoneNumbers.forEach(element => {
          phoneNumber += element.phoneText
        });
        this.originalPhoneNumbers = phoneNumber
        this.processStringToPhoneNumbers(phoneNumber)
      });
    this.messageTemplatingService.getProcessedTemplates()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((templates) => {
        this.temporaryTemplate = templates
        this.initForm(templates)
      }
      );

    this.messagesHistoryService.getMessages(this.request.id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((messages: any) => {
        if (messages.length > 0) {
          this.requestMessages = messages;
        }
      });

    //GET FILTERS TO TRANSLATE AND WAPP TARGETING
    this.caregiverSelectionService.getFilters().pipe(takeUntil(this.unsubscribe)).subscribe((filters) => {
      //filter to search with endpoint
      this.filtersObj = filters
      //filter obj to save on history
      if (this.request.requestPersonalInfo.requestCategory === 'BABYSITTER') {
        this.translations = {
          homework: 'Compiti',
          cleaning: 'Pulizia',
          cooking: 'Cucinare',
          taxi: 'Taxi',
          licence: 'Patente',
          car: 'Auto',
          smoker: 'Fumatore',
          greenpass: 'Green Pass',
          disostruzione: 'Disostruzione',
          fhsub: 'Sostituto Famiglia',
          ref: 'Referenze',
          rating: 'Valutazione',
          ignored: 'Ignorato',
          fullCV: 'CV Completo',
          minAge: 'Età Minima',
          maxAge: 'Età Massima',
          page: 'Pagina',
          distance: 'Distanza',
          days: {
            sun: 'Domenica',
            mon: 'Lunedì',
            tue: 'Martedì',
            wed: 'Mercoledì',
            thu: 'Giovedì',
            fri: 'Venerdì',
            sat: 'Sabato'
          },
          hours: {
            mor: 'Mattina',
            aft: 'Pomeriggio',
            eve: 'Sera'
          },
          contract: {
            occ: 'Occasionale',
            part: 'Part-time',
            full: 'Full-time'
          },
          lang: {
            english: 'Inglese',
            french: 'Francese',
            german: 'Tedesco',
            italian: 'Italiano',
            spanish: 'Spagnolo'
          },
          exp: {
            cluster1: 'Esperienza 1',
            cluster2: 'Esperienza 2',
            cluster3: 'Esperienza 3',
            cluster4: 'Esperienza 4'
          },
        }
        this.translatedFiltersObj = this.messageTemplatingService.translateObjectFilters(filters, this.translations)
      } else if (this.request.requestPersonalInfo.requestCategory === 'PETSITTER') {

        this.filtersObj = filters

        this.translations = {
          catHealing: {
            cat: 'Curare gatti',
          },
          catHosting: {
            cat: 'Ospitare gatti',
          },
          dogHealing: {
            dog: 'Curare cani 0-7 kg',
            dog1: 'Curare cani 7-15 kg',
            dog2: 'Curare cani 15-30 kg',
            dog3: 'Curare cani 30-45 kg',
            dog4: 'Curare cani 45+ kg',
          },
          dogHosting: {
            dog: 'Ospitare cani 0-7 kg',
            dog1: 'Ospitare cani 7-15 kg',
            dog2: 'Ospitare cani 15-30 kg',
            dog3: 'Ospitare cani 30-45 kg',
            dog4: 'Ospitare cani 45+ kg',
          },
          disability: 'Esperienza con animali con disabilità psichiche',
          drugs: 'Somministrazione farmaci',
          homeService: 'A domicilio',
          hosting: 'A casa del sitter',
          illness: 'Esperienza con animali con problemi comportamentali',
          nightShift: 'Soggiorno notturno',
          pension: 'Pensione',
          walking: 'Passeggiata cani',
          fhsub: 'Iscrizione a Family+Happy',
          minAge: 'Età Minima',
          maxAge: 'Età Massima',
          page: 'Pagina',
          distance: 'Distanza',
          days: {
            sun: 'Domenica',
            mon: 'Lunedì',
            tue: 'Martedì',
            wed: 'Mercoledì',
            thu: 'Giovedì',
            fri: 'Venerdì',
            sat: 'Sabato'
          },
          hours: {
            mor: 'Mattina',
            aft: 'Pomeriggio',
            eve: 'Sera'
          },
          contract: {
            occ: 'Occasionale',
            part: 'Part-time',
            full: 'Full-time'
          },
          lang: {
            english: 'Inglese',
            french: 'Francese',
            german: 'Tedesco',
            italian: 'Italiano',
            spanish: 'Spagnolo'
          },
          exp: {
            cluster1: 'Con cuccioli',
            cluster2: 'Con adulti',
            cluster3: 'Con anziani'
          },
        }
        if (this.translations !== undefined) {
          this.translatedFiltersObj = this.messageTemplatingService.translateObjectFilters(filters, this.translations)
        }
      }
    })

    this.boardService.getAdStatus(this.request.id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (req: any) => {
          if (req.status === 'true') {
            this.updateAd = true;
          } else {
            console.log('L\'annuncio relativo alla richiesta non è ancora stato generato.');
          }
        }
      });
    this.temporaryTemplate = this.messageTemplatingService.templates

    this.target$ = this.range.valueChanges.pipe(switchMap((value) => {
      // if (this.isAdvancedSearch === false) {
        if (this.request.requestPersonalInfo.requestCategory === 'BABYSITTER') {
          let range = null
          if (value !== "Province") {
            range = parseInt(value);
          }
          return this.babysitterService.getWhatsappTargets(this.request.requestPersonalInfo.provincia, this.request.requestPersonalInfo.latitude, this.request.requestPersonalInfo.longitude, range);
        } else if (this.request.requestPersonalInfo.requestCategory === 'PETSITTER') {
          let range = null
          if (value !== "Province") {
            range = parseInt(value);
          }
          return this.petsitterService.getWhatsappTargets(this.request.requestPersonalInfo.provincia, this.request.requestPersonalInfo.latitude, this.request.requestPersonalInfo.longitude, range);
        } else if (this.request.requestPersonalInfo.requestCategory === 'NURSE') {
          let range = null
          if (value !== "Province") {
            range = parseInt(value);
          }
          return this.nurseService.getWhatsappTargets(this.request.requestPersonalInfo.provincia, this.request.requestPersonalInfo.latitude, this.request.requestPersonalInfo.longitude, range);
        } else if (this.request.requestPersonalInfo.requestCategory === 'COLF') {
          let range = null
          if (value !== "Province") {
            range = parseInt(value);
          }
          return this.colfService.getWhatsappTargets(this.request.requestPersonalInfo.provincia, this.request.requestPersonalInfo.latitude, this.request.requestPersonalInfo.longitude, range);
        }
        else {
          return value
        }
      // } else {
      //   if (this.filtersObj !== undefined || this.filtersObj !== null) {
      //     if (this.request.requestPersonalInfo.requestCategory === 'BABYSITTER') {
      //       let range = null
      //       if (value !== "Province") {
      //         range = parseInt(value);
      //       }
      //       let filters = {
      //         ...this.filtersObj,
      //         days: this.babysitterService.fromAvailObjectToString(this.filtersObj.days, 'days'),
      //         hours: this.babysitterService.fromAvailObjectToString(this.filtersObj.hours, 'hours'),
      //         contract: this.babysitterService.fromAvailObjectToString(this.filtersObj.contract, 'contract'),
      //       }
      //       return this.babysitterService.getWhatsappFilteredTargets(filters, this.request.requestPersonalInfo.latitude, this.request.requestPersonalInfo.longitude, range);
      //     }
      //     else if (this.request.requestPersonalInfo.requestCategory === 'PETSITTER') {
      //       let range = null
      //       if (value !== "Province") {
      //         range = parseInt(value);
      //       }
      //       let filters = {
      //         ...this.filtersObj,
      //         days: this.petsitterService.fromAvailObjectToString(this.filtersObj.days, 'days'),
      //         hours: this.petsitterService.fromAvailObjectToString(this.filtersObj.hours, 'hours'),
      //         contract: this.petsitterService.fromAvailObjectToString(this.filtersObj.contract, 'contract'),
      //         catHealing: this.petsitterService.fromAvailObjectToString(this.filtersObj.catHealing, 'catHealing'),
      //         catHosting: this.petsitterService.fromAvailObjectToString(this.filtersObj.catHosting, 'catHosting'),
      //         dogHealing: this.petsitterService.fromAvailObjectToString(this.filtersObj.dogHealing, 'dogHealing'),
      //         dogHosting: this.petsitterService.fromAvailObjectToString(this.filtersObj.dogHosting, 'dogHosting'),
      //       }
      //       return this.petsitterService.getWhatsappFilteredTargets(filters, this.request.requestPersonalInfo.latitude, this.request.requestPersonalInfo.longitude, range);
      //     }
      //     else if (this.request.requestPersonalInfo.requestCategory === 'NURSE') {
      //       let range = null
      //       if (value !== "Province") {
      //         range = parseInt(value);
      //       }
      //       let filters = {
      //         ...this.filtersObj,
      //         days: this.petsitterService.fromAvailObjectToString(this.filtersObj.days, 'days'),
      //         hours: this.petsitterService.fromAvailObjectToString(this.filtersObj.hours, 'hours'),
      //         contract: this.petsitterService.fromAvailObjectToString(this.filtersObj.contract, 'contract'),
      //       }
      //       return this.nurseService.getWhatsappFilteredTargets(filters, this.request.requestPersonalInfo.latitude, this.request.requestPersonalInfo.longitude, range);
      //     } else if (this.request.requestPersonalInfo.requestCategory === 'COLF') {
      //       let range = null
      //       if (value !== "Province") {
      //         range = parseInt(value);
      //       }
      //       let filters = {
      //         ...this.filtersObj,
      //         days: this.petsitterService.fromAvailObjectToString(this.filtersObj.days, 'days'),
      //         hours: this.petsitterService.fromAvailObjectToString(this.filtersObj.hours, 'hours'),
      //         contract: this.petsitterService.fromAvailObjectToString(this.filtersObj.contract, 'contract'),
      //       }
      //       return this.colfService.getWhatsappFilteredTargets(filters, this.request.requestPersonalInfo.latitude, this.request.requestPersonalInfo.longitude, range);
      //     }
      //   }
      // }
    }));
    this.target$.pipe(takeUntil(this.unsubscribe)).subscribe((value: any) => this.targets = value.length)
  }

  activeAdvancedSearch() {
    this.isAdvancedSearch = !this.isAdvancedSearch
  }

  sendWhatsappMessage(data: WhatsappBody) {
    console.log('Send Whatsapp:', data);
    let observable: Observable<string[]>;

    let range = null
    let targetReached = 0
    if (this.range.value !== "Province") {
      range = parseInt(this.range.value);
    }

    switch (this.request.requestPersonalInfo.requestCategory) {
      case 'COLF':
        data.type = 'COLF_AD';
        observable = this.colfService.getWhatsappTargets(this.request.requestPersonalInfo.provincia, this.request.requestPersonalInfo.latitude, this.request.requestPersonalInfo.longitude, range);
        break;
      case 'NURSE':
        data.type = 'NURSE_AD';
        observable = this.nurseService.getWhatsappTargets(this.request.requestPersonalInfo.provincia, this.request.requestPersonalInfo.latitude, this.request.requestPersonalInfo.longitude, range);
        break;
      case 'PETSITTER':
        data.type = 'PETSITTER_AD';
        observable = this.petsitterService.getWhatsappTargets(this.request.requestPersonalInfo.provincia, this.request.requestPersonalInfo.latitude, this.request.requestPersonalInfo.longitude, range);
        break;
      case 'BABYSITTER':
      default:
        data.type = 'BABYSITTER_AD';
        // observable = this.babysitterService.getWhatsappTargets(this.request.requestPersonalInfo.provincia);
        observable = this.babysitterService.getWhatsappTargets(this.request.requestPersonalInfo.provincia, this.request.requestPersonalInfo.latitude, this.request.requestPersonalInfo.longitude, range);
        break;
    }
    observable.pipe(mergeMap((recipients) => {
      if (environment.production) {
        data.recipients = recipients;
        targetReached = recipients.length

      } else {
        const user = JSON.parse(localStorage.getItem("user") ?? '') as User;
        data.recipients = [user.phone];
        targetReached = recipients.length
      }

      return this.requestService.sendWhatsappMessage(data, this.updateAd, targetReached);
    })).subscribe({
      next: (res) => {
        this.modalService.showSuccessMessage(`Messaggio Whatsapp inviato con successo!`);
        this.updateAd = true;
      },
      error: (error) => {
        this.modalService.showErrorMessage(`Attenzione 500 - Si è verificato un errore imprevisto nell'invio del messaggio Whatsapp. Se l'errore persiste, contattare il supporto tecnico.`);
      }
    });
  }

  updateAdTitle() {
    this.request.timestamp = new Date();
    this.requestService.updateRequest(this.request).subscribe((response) => {
      this.modalService.showSuccessMessage(`Titolo annuncio aggiornato correttamente.`);
      this.stateManager.setRequest(this.request);
    })
    this.messages.get('tate')?.setValue(this.requestService.tateSubject());
  }

  setStep(step: number) {
    this.step = step;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  removeAttachment(attachment: CaregiverAttachment) {
    let index = this.attachments.indexOf(attachment)
    if (index !== -1) {
      this.attachments.splice(index, 1);
    }
  }

  removePhoneNumber(phoneNumberObj: PhoneNumbersObj) {
    let index = this.phoneNumbersArray.indexOf(phoneNumberObj)
    if (index !== -1) {
      this.phoneNumbersArray.splice(index, 1);
      this.processStringfromArray()
      this.messageTemplatingService.updateTemplates()
    }
  }

  processStringToPhoneNumbers(message: string) {
    //convert message to contact list attachment
    this.phoneNumbersArray = []
    // const regex = /<p>([^:]+): ([\d\s]+)<\/p>/g;
    const regex = /<p>([^:]+): ([\+\d\s]+)<\/p>/g;
    let match;
    while ((match = regex.exec(message)) !== null) {
      this.phoneNumbersArray.push({ name: match[1].trim(), phoneNumbers: match[2].trim() });
    }
  }

  processStringfromArray() {
    //convert contactList attachment to string
    let string = ''
    this.phoneNumbersArray.forEach((entity) => {
      string += `<p>${entity.name}: ${entity.phoneNumbers}</p>`
    })
    this.messageTemplatingService.phoneText.next(string)
  }

  updateAttachment() {
    // this.messageTemplatingService.updateCaregiverAttachments()
    this.messageTemplatingService.updateTemplates()
  }

  updatePhoneNumbers() {
    this.messageTemplatingService.updateCaregiverPhoneNumbers()
    this.messageTemplatingService.updateTemplates()
  }

  sendMessage(section: any, index: number) {
    this.modalService.showLoadingWithMessage('Invio in corso...');
    try {
      const subjectField: string = this.sections.find(item => item.id === section)?.subject || '';
      const messageWithStyle = '<style>p, li {font-size:16px; margin:0px;} .ql-size-small{font-size: 12px;} body, p, span, li, strong, em {font-family: Verdana, sans-serif;}</style>' + this.messages.get('' + section)?.value
      const messageBody = juice(messageWithStyle)
      this.requestService.sendMessage(section, this.messages.get(subjectField)?.value, messageBody, this.request.requestPersonalInfo.provincia, this.updateAd);
    } catch (err) {
      this.modalService.showErrorMessage(`Si è verificato un errore nell'invio della mail.`);
    } finally {
      this.updateAd = true;
    }
  }

  getSectionTitle(section: number) {
    let text = '';

    let mailTate = this.requestMessages ? this.requestMessages.filter(message => message.type === 'Email Tate').sort((a, b) => Number(new Date(a.sentTimestamp)) - Number(new Date(b.sentTimestamp))) : [];
    let mailPreventivo = this.requestMessages ? this.requestMessages.filter(message => message.type === 'Email Preventivo').sort((a, b) => Number(new Date(a.sentTimestamp)) - Number(new Date(b.sentTimestamp))) : [];
    let mailProfili = this.requestMessages ? this.requestMessages.filter(message => message.type === 'Email Invio Profili').sort((a, b) => Number(new Date(a.sentTimestamp)) - Number(new Date(b.sentTimestamp))) : [];
    let mailCellulare = this.requestMessages ? this.requestMessages.filter(message => message.type === 'Email Cellulare').sort((a, b) => Number(new Date(a.sentTimestamp)) - Number(new Date(b.sentTimestamp))) : [];

    if (!this.requestMessages) {
      return this.sections[section].title;
    }
    switch (section) {
      case 1:
        text = !(mailTate.length > 0) ? this.sections[section].title : this.sections[section].title + ' - Inviata il ' + new Date(mailTate[mailTate.length - 1].sentTimestamp).toLocaleDateString();
        break;
      case 2:
        text = !(mailPreventivo.length > 0) ? this.sections[section].title : this.sections[section].title + ' - Inviata il ' + new Date(mailPreventivo[mailPreventivo.length - 1].sentTimestamp).toLocaleDateString();
        break;
      case 3:
        text = !(mailProfili.length > 0) ? this.sections[section].title : this.sections[section].title + ' - Inviata il ' + new Date(mailProfili[mailProfili.length - 1].sentTimestamp).toLocaleDateString();
        break;
      case 4:
        text = !(mailCellulare.length > 0) ? this.sections[section].title : this.sections[section].title + ' - Inviata il ' + new Date(mailCellulare[mailCellulare.length - 1].sentTimestamp).toLocaleDateString();
        break;
      default:
        text = this.sections[section].title;
        break;
    }
    return text;
  }

  private initForm(templates: MailTemplate[]) {
    if (templates.length > 0) {
      const quoteTemplate = templates.find(item => item.type === MailTemplateType.QUOTATION);
      const profilesTemplate = templates.find(item => item.type === MailTemplateType.PROFILES);
      const contactTemplate = templates.find(item => item.type === MailTemplateType.CONTACTS);
      this.messages.setValue({
        tate: this.requestService.tateSubject(),
        1: this.messageTemplatingService.emailTate(),
        preventivo: quoteTemplate?.subject,
        2: quoteTemplate?.text,
        esito: profilesTemplate?.subject ?? '',
        3: profilesTemplate?.text ?? '',
        contatti: contactTemplate?.subject,
        4: contactTemplate?.text
      });
    } else {
      this.messages.setValue({
        tate: this.requestService.tateSubject(),
        1: this.messageTemplatingService.emailTate(),
        preventivo: '',
        2: '',
        esito: '',
        3: '',
        contatti: '',
        4: ''
      });
    }

  }
  createPaymentLink() {
    this.messageTemplatingService.updatePaymentUrl().pipe(takeUntil(this.unsubscribe)).subscribe((value) => {
      let paymentUpdate = value
      if (paymentUpdate !== '') {
        this.paymentURL = paymentUpdate
        this.modalService.showSuccessMessage(`Link di pagamento aggiornato correttamente.`);
      } else {
        this.modalService.showErrorMessage(`Impossibile aggiornare link di pagamento`);
      }
    })
  }
  copyToClipboard() {
    navigator.clipboard.writeText(this.paymentURL);
    this.modalService.showSuccessMessage('Link di pagamento copiato con successo! Ora puoi incollarlo dove vuoi.');
  }
  updateMsgHistory() {
    this.messagesHistoryService.getMessages(this.request.id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((messages: any) => {
        if (messages.length > 0) {
          this.requestMessages = messages;
        }
      });
  }

  saveAppointment() {
    if (!this.appointmentDate) {
      this.modalService.showErrorMessage('Attenzione! Non è stata impostata nessuna data di colloquio.');
    } else {
      if (!this.scheduledAppointment) {
        this.scheduledAppointment = {
          entityLinked: `${this.requestID}`,
          timestamp: this.appointmentDate,
          type: ScheduledAppointmentType.CAREGIVER_PRESENTATION,
          createdBy: this.userStorageService.getOperatorName()
        };
      }
      let obs: Observable<ScheduledAppointment>;
      if (this.scheduledAppointment.id) {
        this.scheduledAppointment.timestamp = this.appointmentDate;
        // Update appointment in database
        obs = this.scheduledAppointmentService.updateScheduledAppointment(this.scheduledAppointment.id, this.scheduledAppointment!)
      } else {
        // Create appointment in database
        obs = this.scheduledAppointmentService.createScheduledAppointment(this.scheduledAppointment!);
      }
      obs.subscribe((appointment) => {
        this.scheduledAppointment = appointment;
        // Create the appointment three hours later than the appoinment date
        const reminderDate = new Date((new Date()).getTime() + 3 * 3600 * 1000);
        // Check if the appointment is for a B2C Service or not
        let observable: Observable<any>;
        if (this.request.requestPersonalInfo.serviceLinked.customerType === CustomerType.B2C) {
          // Create task to send reminder
          observable = this.scheduledAppointmentService.createAppointmentTask(appointment, reminderDate);
        } else {
          observable = of(null);
        }
        observable.subscribe(() => {
          this.modalService.showSuccessMessage('Appuntamento impostato con successo!');
        }, () => {
          this.modalService.showErrorMessage(`Non è stato possibile impostare l'appuntamento. Si è verificato un errore imprevisto.`);
        });
      })
    }
  }

  deleteAppointment() {
    // Elimina this.scheduledAppointment
    if (this.scheduledAppointment && this.scheduledAppointment.id) {
      const appointment = this.scheduledAppointment;
      this.scheduledAppointmentService.deleteScheduledAppointment(this.scheduledAppointment.id).subscribe(() => {
        this.modalService.showSuccessMessage('Appuntamento eliminato con successo!');
        this.appointmentDate = '';
        this.scheduledAppointment = undefined;
        // Elimina eventuali task da Firebase
        this.scheduledAppointmentService.deleteAppointmentTask(appointment).subscribe();
      }, () => {
        this.modalService.showErrorMessage(`Non è stato possibile eliminare l'appuntamento. Si è verificato un errore imprevisto.`);
      })
    }
  }
  // Funzione per tradurre l'oggetto filtri
  // translateObject(obj: any, translations: any): any {
  //   let translatedObject: any = {};
  //   for (let key in obj) {
  //     // Se il valore è un oggetto annidato, esegui la ricorsione
  //     if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
  //       let nestedKey = key
  //       translatedObject[nestedKey] = this.translateObject(obj[key], translations[key]);
  //     } else {
  //       translatedObject[translations[key] || key] = obj[key];
  //     }
  //   }
  //   return translatedObject;
  // }

}
