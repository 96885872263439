import { ServiceOverview } from './../../shared/models/service-overview.modet';
import { WhatsappBody } from './../smartphone-container/smartphone-container.component';
import { environment } from './../../../environments/environment';
import { Injectable, OnDestroy } from '@angular/core';
import { ColfRequestDetail, CustomerType, MailTemplate, ModalService, ProductType, Request, RequestCategory, RequestNurseDetail, RequestPetsitterDetail, RequestUtilitiesService } from 'npx-family-happy-common';
import { BehaviorSubject, Subject, map, of, shareReplay, takeUntil } from 'rxjs';
import { RouterStateManagerService } from 'src/app/shared/services/router-state-manager/router-state-manager.service';
import { EmailTemplateService } from 'src/app/backoffice/email/email-services/email.service';
import { CaregiverSelectionService } from '../caregiver-scoreboard/caregiver-selection/caregiver-selection-service/caregiver-selection.service';
import { CaregiverSelectionRecord } from "src/app/shared/models/caregiverSelectionRecord.model";
import { CaregiverAttachment } from 'src/app/shared/models/caregiver-attachment.model';
import { ServiceServices } from 'src/app/backoffice/services/service-services/service.services';
import { PaymentService } from 'src/app/backoffice/payments/payment-service/payment.service';
import { RequestPaymentBody } from 'src/app/shared/models/request-payment-body.model';
import { getURLRelated } from 'src/app/shared/utils.functions';
import { CaregiverChosenAttachment } from 'src/app/shared/models/caregiver-chosen-attachments.model';

@Injectable({
  providedIn: 'root'
})
export class RequestMessagesTemplatingService implements OnDestroy {
  private petsitterDetail!: RequestPetsitterDetail;
  private nurseDetail!: RequestNurseDetail;
  private colfDetail!: ColfRequestDetail;
  private request!: Request;
  private dictionary: any;
  private serviceOverview: ServiceOverview | undefined = undefined;
  script: BehaviorSubject<string> = new BehaviorSubject('');
  templates: MailTemplate[] = [];
  private paymentURL: string = '';
  private messagesTemplateObservable = new BehaviorSubject<MailTemplate[]>([]);

  private caregiverSelected: CaregiverSelectionRecord[] = [];
  private attachments = new BehaviorSubject<CaregiverAttachment[]>([]);
  private caregiverChosen: CaregiverSelectionRecord[] = [];
  phoneNumbers = new BehaviorSubject<CaregiverChosenAttachment[]>([]);
  phoneText = new BehaviorSubject<string>('');
  // phoneNumbers = new BehaviorSubject<string>('');

  private unsubscribe = new Subject<void>();

  constructor(
    private utilities: RequestUtilitiesService,
    private stateManager: RouterStateManagerService,
    private mailTemplateService: EmailTemplateService,
    private caregiverSelectionService: CaregiverSelectionService,
    private servicesService: ServiceServices,
    private paymentService: PaymentService,
    private modalService: ModalService
  ) {
    this.stateManager.getRequestState()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((state) => {
        if (state.request) {
          if (!this.request || this.request.id !== state.request.id) {
            this.templates = [];
            this.paymentURL = '';
          }
          this.request = state.request;
          if (this.request.requestPersonalInfo.serviceLinked && (this.serviceOverview === undefined || this.serviceOverview.serviceID !== this.request.requestPersonalInfo.serviceLinked.id)) {
            if (this.serviceOverview && this.serviceOverview.serviceID !== this.request.requestPersonalInfo.serviceLinked.id) {
              // If the service has been updated, reset the paymentURL and the mail templates, in order to recompute them
              this.templates = [];
              this.paymentURL = '';
            }
          }
          this.updateTemplates();
        }
      });
    this.caregiverSelectionService.getStoredCaregiverRecords()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((records) => {
        // Compute caregiver selected and relative attachments
        this.caregiverSelected = records.filter(item => !item.discarded && !item.chosen && item.selected && item.suitable);
        this.updateCaregiverAttachments();

        // Compute caregiver chosen and relative phone numbers
        this.caregiverChosen = records.filter(item => !item.discarded && item.chosen && item.selected && item.suitable);
        this.updateCaregiverPhoneNumbers();

        this.updateTemplates();
      })
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  setPetsitterDetail(data: RequestPetsitterDetail) {
    this.petsitterDetail = data;
  }

  setNurseDetail(data: RequestNurseDetail) {
    this.nurseDetail = data;
  }

  setColfDetail(data: ColfRequestDetail) {
    this.colfDetail = data;
  }

  computeNetHourCostByService(flatCost: number, flatCostTiming: number, province: string, moreThanOne: boolean, handicap: boolean, badWorkHours: boolean, netHourCost: number, service: string, withholdingTax = false) {
    let pricing = '';
    if (!(flatCost !== null && flatCost > 0)) {
      if (flatCostTiming === 6) {
        const netCostHour: number = this.utilities.computeNetPrice(province, moreThanOne, handicap, badWorkHours, netHourCost);
        pricing = `${netCostHour}€/ passaggio`;
        if (service === 'Pacchetto ore' || withholdingTax) {
          return `${pricing} (${(netCostHour / 1.25).toFixed(2)}€ mediante Bonifico + ${(netCostHour * 0.2).toFixed(2)}€ in Rit.Acconto)`;
        } else {
          return pricing;
        }
      } else {
        const netCostHour: number = this.utilities.computeNetPrice(province, moreThanOne, handicap, badWorkHours, netHourCost);
        pricing = `${netCostHour}€/ora`;
        if (service === 'Pacchetto ore' || withholdingTax) {
          return `${pricing} (${(netCostHour / 1.25).toFixed(2)}€ mediante Bonifico + ${(netCostHour * 0.2).toFixed(2)}€ in Rit.Acconto)`;
        } else {
          return pricing;
        }
      }
    } else {
      pricing = this.utilities.computeFlatPrice(flatCost, flatCostTiming);
      if (service === 'Pacchetto ore' || withholdingTax) {
        return `${pricing} (${(flatCost / 1.25).toFixed(2)}€ mediante Bonifico + ${(flatCost * 0.2).toFixed(2)}€ in Rit.Acconto)`;
      } else {
        return pricing;
      }
    }
  }

  getWhatsappData(request: Request) {
    // Common information
    const province = request.requestPersonalInfo.provincia;

    let type;
    switch (request.requestPersonalInfo.requestCategory) {
      case 'COLF':
        type = 'COLF_AD';
        break;
      case 'NURSE':
        type = 'NURSE_AD';
        break;
      case 'PETSITTER':
        type = 'PETSITTER_AD';
        break;
      case 'BABYSITTER':
      default:
        type = 'BABYSITTER_AD';
        break;
    }

    // Patch petsitter data
    if (request.requestPersonalInfo.requestCategory === 'PETSITTER') {
      request.requestDetail.requestDescription = this.petsitterDetail.requestDescription;
      request.requestDetail.starting = this.petsitterDetail.starting;
      request.requestDetail.continuousJob = this.petsitterDetail.continuousJob;
      request.requestDetail.estimatedLasting = this.petsitterDetail.estimatedLasting;
      request.requestDetail.flatCost = this.petsitterDetail.flatCost;
      request.requestDetail.flatCostTiming = this.petsitterDetail.flatCostTiming;
      request.requestDetail.moreThanOne = this.petsitterDetail.moreThanOne;
      request.requestDetail.handicap = false;
      request.requestDetail.badWorkHours = this.petsitterDetail.badWorkHours;
      request.requestDetail.netHourCost = this.petsitterDetail.netHourCost;
      request.requestDetail.taxisitter = this.petsitterDetail.taxisitter;
      request.requestDetail.withholdingTax = this.petsitterDetail.withholdingTax;
    }
    // Patch colf data
    if (request.requestPersonalInfo.requestCategory === 'COLF') {
      request.requestDetail.requestDescription = this.colfDetail.requestDescription;
      request.requestDetail.starting = this.colfDetail.starting;
      request.requestDetail.continuousJob = this.colfDetail.continuousJob;
      request.requestDetail.estimatedLasting = this.colfDetail.estimatedLasting;
      request.requestDetail.flatCost = this.colfDetail.flatCost;
      request.requestDetail.flatCostTiming = this.colfDetail.flatCostTiming;
      request.requestDetail.moreThanOne = this.colfDetail.moreThanOne;
      request.requestDetail.handicap = false;
      request.requestDetail.badWorkHours = this.colfDetail.badWorkHours;
      request.requestDetail.netHourCost = this.colfDetail.netHourCost;
      request.requestDetail.taxisitter = this.colfDetail.taxisitter;
      request.requestDetail.withholdingTax = this.colfDetail.withholdingTax;
    }

    // Patch nurse data
    if (request.requestPersonalInfo.requestCategory === 'NURSE') {
      request.requestDetail.requestDescription = this.nurseDetail.requestDescription;
      request.requestDetail.starting = this.nurseDetail.starting;
      request.requestDetail.continuousJob = this.nurseDetail.continuousJob;
      request.requestDetail.estimatedLasting = this.nurseDetail.estimatedLasting;
      request.requestDetail.flatCost = this.nurseDetail.flatCost;
      request.requestDetail.flatCostTiming = this.nurseDetail.flatCostTiming;
      request.requestDetail.moreThanOne = this.nurseDetail.moreThanOne;
      request.requestDetail.handicap = false;
      request.requestDetail.badWorkHours = this.nurseDetail.badWorkHours;
      request.requestDetail.netHourCost = this.nurseDetail.netHourCost;
      request.requestDetail.taxisitter = this.nurseDetail.taxisitter;
      request.requestDetail.withholdingTax = this.nurseDetail.withholdingTax;
    }

    // Specific data
    const timing = request.requestPersonalInfo.requestCategory === 'BABYSITTER' ? 'Inizio stimato il ' : '';
    const nBambini = request.requestDetail.numberOfChildren;
    const genereBambino = request.requestDetail.childrenGender;
    const moreThanOne = request.requestDetail.moreThanOne;
    const handicap = request.requestDetail.handicap;
    const badWorkHours = request.requestDetail.badWorkHours;
    const netHourCost = request.requestDetail.netHourCost;
    const flatCost = request.requestDetail.flatCost;
    const flatCostTiming = request.requestDetail.flatCostTiming;
    const etaBambini = request.requestDetail.ageOfChildrenText ? request.requestDetail.ageOfChildren + ' ' + request.requestDetail.ageOfChildrenText : request.requestDetail.ageOfChildren;
    const costoOrarioNetto = this.computeNetHourCostByService(flatCost, flatCostTiming, province,
      moreThanOne, handicap, badWorkHours, netHourCost, request.requestPersonalInfo.service, request.requestDetail.withholdingTax);
    const bisognoFinoA = request.requestDetail.estimatedLasting === null || request.requestDetail.estimatedLasting === '' ? '' : ' - La famiglia ha bisogno fino al ' + new Date(request.requestDetail.estimatedLasting).toLocaleDateString('it-IT', { day: '2-digit', month: 'long', year: 'numeric' });

    const data: WhatsappBody = {
      recipients: [],
      role: this.utilities.computeCategories(request.requestDetail.category),
      childInfo: nBambini + " " + genereBambino + " di " + etaBambini,
      city: request.requestPersonalInfo.parentCity + (request.requestPersonalInfo.parentZone ? ' - ' + request.requestPersonalInfo.parentZone : ''),
      description: request.requestDetail.requestDescription,
      starting: timing + this.utilities.getBabysittingStartingDate(request.requestDetail.starting) + (request.requestDetail.continuousJob ? ' - Lavoro Continuativo' : '') + bisognoFinoA,
      wage: costoOrarioNetto + (request.requestDetail.taxisitter ? " + rimborso spese benzina" : ''),
      requestID: `${request.id}`,
      type
    }

    return data;
  }

  emailTate() {
    // Common information
    const idRichiesta = this.request.id;
    const linkBacheca = environment.production ? `https://babysitter-familyhappy.web.app/richiesta/${this.request.id}` : `https://babysitter-familyhappy-test.web.app/richiesta/${this.request.id}`;
    const citta = this.request.requestPersonalInfo.parentCity;
    const zona = this.request.requestPersonalInfo.parentZone ? ' (zona ' + this.request.requestPersonalInfo.parentZone + ')' : '';
    const province = this.request.requestPersonalInfo.provincia;

    // Patch petsitter data
    if (this.request.requestPersonalInfo.requestCategory === 'PETSITTER') {
      this.request.requestDetail.requestDescription = this.petsitterDetail.requestDescription;
      this.request.requestDetail.starting = this.petsitterDetail.starting;
      this.request.requestDetail.continuousJob = this.petsitterDetail.continuousJob;
      this.request.requestDetail.estimatedLasting = this.petsitterDetail.estimatedLasting;
      this.request.requestDetail.flatCost = this.petsitterDetail.flatCost;
      this.request.requestDetail.flatCostTiming = this.petsitterDetail.flatCostTiming;
      this.request.requestDetail.moreThanOne = this.petsitterDetail.moreThanOne;
      this.request.requestDetail.handicap = false;
      this.request.requestDetail.badWorkHours = this.petsitterDetail.badWorkHours;
      this.request.requestDetail.netHourCost = this.petsitterDetail.netHourCost;
      this.request.requestDetail.taxisitter = this.petsitterDetail.taxisitter;
    }

    // Patch nurse data
    if (this.request.requestPersonalInfo.requestCategory === 'NURSE') {
      this.request.requestDetail.requestDescription = this.nurseDetail.requestDescription;
      this.request.requestDetail.starting = this.nurseDetail.starting;
      this.request.requestDetail.continuousJob = this.nurseDetail.continuousJob;
      this.request.requestDetail.estimatedLasting = this.nurseDetail.estimatedLasting;
      this.request.requestDetail.flatCost = this.nurseDetail.flatCost;
      this.request.requestDetail.flatCostTiming = this.nurseDetail.flatCostTiming;
      this.request.requestDetail.moreThanOne = this.nurseDetail.moreThanOne;
      this.request.requestDetail.handicap = false;
      this.request.requestDetail.badWorkHours = this.nurseDetail.badWorkHours;
      this.request.requestDetail.netHourCost = this.nurseDetail.netHourCost;
      this.request.requestDetail.taxisitter = this.nurseDetail.taxisitter;
    }

    // Patch nurse data
    if (this.request.requestPersonalInfo.requestCategory === 'COLF') {
      this.request.requestDetail.requestDescription = this.colfDetail.requestDescription;
      this.request.requestDetail.starting = this.colfDetail.starting;
      this.request.requestDetail.continuousJob = this.colfDetail.continuousJob;
      this.request.requestDetail.estimatedLasting = this.colfDetail.estimatedLasting;
      this.request.requestDetail.flatCost = this.colfDetail.flatCost;
      this.request.requestDetail.flatCostTiming = this.colfDetail.flatCostTiming;
      this.request.requestDetail.moreThanOne = this.colfDetail.moreThanOne;
      this.request.requestDetail.handicap = false;
      this.request.requestDetail.badWorkHours = this.colfDetail.badWorkHours;
      this.request.requestDetail.netHourCost = this.colfDetail.netHourCost;
      this.request.requestDetail.taxisitter = this.colfDetail.taxisitter;
    }

    // Specific data
    const ruoloRichiesto = this.utilities.computeCategories(this.request.requestDetail.category);
    const nBambini = this.request.requestDetail.numberOfChildren;
    const genereBambino = this.request.requestDetail.childrenGender;
    const etaBambini = this.request.requestDetail.ageOfChildrenText ? this.request.requestDetail.ageOfChildren + ' ' + this.request.requestDetail.ageOfChildrenText : this.request.requestDetail.ageOfChildren;
    const childInfo = nBambini !== null ? ' per ' + nBambini + ' ' + genereBambino + ' di ' + etaBambini : '';
    const descrizioneImpegno = this.request.requestDetail.requestDescription ? this.request.requestDetail.requestDescription.replaceAll('\r\n', '') : '';
    const inizioStimato = this.utilities.getBabysittingStartingDate(this.request.requestDetail.starting);
    const taxisitter = this.request.requestDetail.taxisitter;
    const text = this.request.requestDetail.continuousJob ? ' - Lavoro Continuativo' : '';
    const bisognoFinoA = this.request.requestDetail.estimatedLasting === null ? '' : this.request.requestDetail.estimatedLasting === '' ? '' : new Date(this.request.requestDetail.estimatedLasting).toLocaleDateString('it-IT', { day: '2-digit', month: 'long', year: 'numeric' });
    const text2 =
      bisognoFinoA !== ''
        ? 'La famiglia ha bisogno fino al ' + bisognoFinoA
        : '';
    const flatCost = this.request.requestDetail.flatCost;
    const flatCostTiming = this.request.requestDetail.flatCostTiming;
    const moreThanOne = this.request.requestDetail.moreThanOne;
    const handicap = this.request.requestDetail.handicap;
    const badWorkHours = this.request.requestDetail.badWorkHours;
    const netHourCost = this.request.requestDetail.netHourCost;
    const retribuzioneTata = this.computeNetHourCostByService(flatCost, flatCostTiming, province,
      moreThanOne, handicap, badWorkHours, netHourCost, this.request.requestPersonalInfo.service, this.request.requestDetail.withholdingTax);
    const text4 = taxisitter
      ? " + rimborso spese benzina"
      : '';

    let message: string = '';
    switch (this.request.requestPersonalInfo.requestCategory) {
      case 'COLF':
        message = `<div style=\"font-size: 16px;padding: 10px;\"><p>Proposta n.${idRichiesta} </p><br>
        <p>Buongiorno dal Team Family+Happy,</p>
        <p>ti scriviamo perché siamo alla ricerca di un* Colf nella città di ${citta} ${zona} per ${descrizioneImpegno}</p><br>
        <p>Inizio Stimato: <span style=\"text-transform: capitalize\">${inizioStimato}</span>${text}</p>
        <p>${text2}</p>
        <p>Remunerazione netta: ${retribuzioneTata}${text4}</p><br>
        <p>-> Per Aderire compilare con i vostri dati questo link: <a href="${linkBacheca}">${linkBacheca}</a> inviando il CV dove emerge la vostra esperienza da badante per la richiesta</p><br>
        <p>Family+Happy</p></div>`;
        break;
      case 'NURSE':
        message = `<div style=\"font-size: 16px;padding: 10px;\"><p>Proposta n.${idRichiesta} </p><br>
        <p>Buongiorno dal Team Family+Happy,</p>
        <p>ti scriviamo perché siamo alla ricerca di un* Badante nella città di ${citta} ${zona} per ${descrizioneImpegno}</p><br>
        <p>Inizio Stimato: <span style=\"text-transform: capitalize\">${inizioStimato}</span>${text}</p>
        <p>${text2}</p>
        <p>Remunerazione netta: ${retribuzioneTata}${text4}</p><br>
        <p>-> Per Aderire compilare con i vostri dati questo link: <a href="${linkBacheca}">${linkBacheca}</a> inviando il CV dove emerge la vostra esperienza da badante per la richiesta</p><br>
        <p>Family+Happy</p></div>`;
        break;
      case 'PETSITTER':
        message = `<div style=\"font-size: 16px;padding: 10px;\"><p>Proposta n.${idRichiesta} </p><br>
        <p>Buongiorno dal Team Family+Happy,</p>
        <p>ti scriviamo perché siamo alla ricerca di un* Petsitter nella città di ${citta} ${zona} per ${descrizioneImpegno}</p><br>
        <p>Inizio Stimato: <span style=\"text-transform: capitalize\">${inizioStimato}</span>${text}</p>
        <p>${text2}</p>
        <p>Remunerazione netta: ${retribuzioneTata}${text4}</p><br>
        <p>-> Per Aderire compilare con i vostri dati questo link: <a href="${linkBacheca}">${linkBacheca}</a> inviando il CV dove emerge la vostra esperienza da petsitter per la richiesta</p><br>
        <p>Family+Happy</p></div>`;
        break;
      case 'BABYSITTER':
      default:
        message = '<div style=\"font-size: 16px;padding: 10px;\"><p>Proposta n.' + idRichiesta + ': </p><br>' +
          '<p>Buongiorno dal Team Family+Happy,</p>' +
          '<p>ti scriviamo perché siamo alla ricerca di un* ' + ruoloRichiesto + childInfo +
          ' per ' + descrizioneImpegno + '</p><br><p>' + citta + zona + '</p><p>Inizio Stimato: <span style=\"text-transform: capitalize\">' + inizioStimato + '</span>' + text + '</p><p>' +
          text2 + '</p><p>Remunerazione netta: ' + retribuzioneTata + text4 + '</p><br><p>-> Per Aderire compilare con i  vostri dati questo link : <a href="' + linkBacheca + '">' + linkBacheca + "</a>"
        'inviando il CV dove emerge la vostra esperienza da babysitter per la richiesta</p>' +
          '<br><p>Family+Happy</p></div>';
    }

    return this.processMessage(message);
  }

  processMessage(message: string) {
    let re = /\$\{([^{}]*({[^{}]*})*[^{}]*)*\}/gm;
    let result = re.exec(message);
    if (this.dictionary) {
      while (result !== null) {
        if (result[0].includes('?')) {
          let textValue = this.replaceFormula(result[0])
          message = message.replaceAll(result[0], textValue)
        } else
          if (this.dictionary[result[0]] || this.dictionary[result[0]] === 0 || this.dictionary[result[0]] === "") {
            message = message.replaceAll(result[0], this.dictionary[result[0]])
          }
        result = re.exec(message);
      };
      result = re.exec(message);
      while (result !== null) {
        if (result[0].includes('?')) {
          let textValue = this.replaceFormula(result[0])
          message = message.replaceAll(result[0], textValue)
        } else if (this.dictionary[result[0]] || this.dictionary[result[0]] === '') {
          message = message.replaceAll(result[0], this.dictionary[result[0]])
        }
        result = re.exec(message);
      };
    }
    return message;
  }

  replaceFormula(text: string) {
    const formulaRegex = /\$\{([^{}]*({[^{}]*})*[^{}]*)*\}/gm;
    const replacedText = text.replaceAll(formulaRegex, (_, match) => {
      let splitResult = match.split('?');
      const variable = splitResult[0].trim();
      splitResult = splitResult[1].trim().split(':');
      const firstValue = splitResult[0].trim().replaceAll('\'', '');
      const secondValue = splitResult[1].trim().replaceAll('\'', '');
      if (this.dictionary[`\${${variable}}`]) {
        return this.processMessage(firstValue);
      } else {
        return this.processMessage(secondValue);
      }
    });
    return this.processMessage(replacedText);
  }

  private async computeDictionary() {
    // Process caregiver selected
    let nBabysitter = 0;
    let pluralSelected = false;
    let textSelected = 'NESSUN PROFILO SELEZIONATO';
    if (this.caregiverSelected.length > 0) {
      nBabysitter = this.caregiverSelected.length;
      pluralSelected = nBabysitter > 1;
      textSelected = nBabysitter > 1 ? 'dei ' + nBabysitter + ' profili</b> selezionati' : 'del profilo</b> selezionato';
    }

    // Process caregiver chosen
    // const babysitterChosen = this.phoneNumbers.getValue().map((BBChosen) => {
    //   return BBChosen.phoneText
    // });
    const babysitterChosen = this.phoneText.getValue();
    const pluralChosen = this.caregiverChosen.length > 1;
    let caregiverName = '';
    if (this.caregiverChosen.length === 1) {
      if (this.request.requestPersonalInfo.requestCategory === RequestCategory.BABYSITTER) {
        caregiverName = this.caregiverChosen[0].babysitter?.info.name ?? '';
      } else if (this.request.requestPersonalInfo.requestCategory === RequestCategory.PETSITTER) {
        caregiverName = this.caregiverChosen[0].petsitter?.info.name ?? '';
      } else if (this.request.requestPersonalInfo.requestCategory === RequestCategory.NURSE) {
        caregiverName = this.caregiverChosen[0].nurse?.info.name ?? '';
      } else if (this.request.requestPersonalInfo.requestCategory === RequestCategory.COLF) {
        caregiverName = this.caregiverChosen[0].colf?.info.name ?? '';
      }
    }
    const textChosen = pluralChosen ? `<p><span style="font-weight: 400;"> A loro ho già anticipato che sono state da lei selezionate, aspettano una sua chiamata a stretto giro per avere informazioni sul suo indirizzo e per mettersi d'accordo per l&rsquo;incontro conoscitivo. Suggeriamo di effettuare l&rsquo;incontro conoscitivo &ldquo;una alla volta&rdquo;.</span></p>` : `<p><span style="font-weight: 400;">Le ho già anticipato che è stata da lei selezionata e aspetta una sua chiamata entro 3 giorni lavorativi per avere informazioni sul suo indirizzo e per mettersi d'accordo per l'incontro conoscitivo.</span></p>`;


    // Specific data
    // const costoTotale = this.utilities.computeTotalPrice(province, moreThanOne, handicap, badWorkHours, netHourCost) * +nSettimane * +oreSettimana * 0.2;
    // const costoTotale = this.utilities.computeTotalPrice(province, moreThanOne, handicap, badWorkHours, netHourCost) * +nSettimane * +oreSettimana;

    const nomeGenitore = this.request.requestPersonalInfo.parentName;
    const nomeOperatore = this.request.requestPersonalInfo.operator.split(' ')[0];
    const service = this.request.requestPersonalInfo.service;
    const province = this.request.requestPersonalInfo.provincia;
    const clientServiceMail = this.utilities.getMailService(this.request.requestPersonalInfo.customer as CustomerType, environment.production);

    // Patch petsitter data
    if (this.request.requestPersonalInfo.requestCategory === 'PETSITTER' && this.petsitterDetail) {
      this.request.requestDetail.flatCost = this.petsitterDetail.flatCost;
      this.request.requestDetail.flatCostTiming = this.petsitterDetail.flatCostTiming;
      this.request.requestDetail.moreThanOne = this.petsitterDetail.moreThanOne;
      this.request.requestDetail.handicap = false;
      this.request.requestDetail.badWorkHours = this.petsitterDetail.badWorkHours;
      this.request.requestDetail.netHourCost = this.petsitterDetail.netHourCost;
      this.request.requestDetail.hoursAmount = this.petsitterDetail.hoursAmount;
      this.request.requestDetail.taxisitter = this.petsitterDetail.taxisitter;
      this.request.requestDetail.packageFlatCost = this.petsitterDetail.packageFlatCost;
    }

    // Patch nurse data
    if (this.request.requestPersonalInfo.requestCategory === 'NURSE' && this.nurseDetail) {
      this.request.requestDetail.requestDescription = this.nurseDetail.requestDescription;
      this.request.requestDetail.starting = this.nurseDetail.starting;
      this.request.requestDetail.continuousJob = this.nurseDetail.continuousJob;
      this.request.requestDetail.estimatedLasting = this.nurseDetail.estimatedLasting;
      this.request.requestDetail.flatCost = this.nurseDetail.flatCost;
      this.request.requestDetail.flatCostTiming = this.nurseDetail.flatCostTiming;
      this.request.requestDetail.moreThanOne = this.nurseDetail.moreThanOne;
      this.request.requestDetail.handicap = false;
      this.request.requestDetail.badWorkHours = this.nurseDetail.badWorkHours;
      this.request.requestDetail.netHourCost = this.nurseDetail.netHourCost;
      this.request.requestDetail.taxisitter = this.nurseDetail.taxisitter;
      this.request.requestDetail.hoursAmount = this.nurseDetail.hoursAmount;
      this.request.requestDetail.packageFlatCost = this.nurseDetail.packageFlatCost;
    }

    // Patch colf data
    if (this.request.requestPersonalInfo.requestCategory === 'COLF' && this.colfDetail) {
      this.request.requestDetail.requestDescription = this.colfDetail.requestDescription;
      this.request.requestDetail.starting = this.colfDetail.starting;
      this.request.requestDetail.continuousJob = this.colfDetail.continuousJob;
      this.request.requestDetail.estimatedLasting = this.colfDetail.estimatedLasting;
      this.request.requestDetail.flatCost = this.colfDetail.flatCost;
      this.request.requestDetail.flatCostTiming = this.colfDetail.flatCostTiming;
      this.request.requestDetail.moreThanOne = this.colfDetail.moreThanOne;
      this.request.requestDetail.handicap = false;
      this.request.requestDetail.badWorkHours = this.colfDetail.badWorkHours;
      this.request.requestDetail.netHourCost = this.colfDetail.netHourCost;
      this.request.requestDetail.taxisitter = this.colfDetail.taxisitter;
      this.request.requestDetail.hoursAmount = this.colfDetail.hoursAmount;
      this.request.requestDetail.packageFlatCost = this.colfDetail.packageFlatCost;
    }

    // Specific data
    const flatCost = this.request.requestDetail.flatCost;
    const flatCostTiming = this.request.requestDetail.flatCostTiming;
    const moreThanOne = this.request.requestDetail.moreThanOne;
    const handicap = this.request.requestDetail.handicap;
    const badWorkHours = this.request.requestDetail.badWorkHours;
    const netHourCost = this.request.requestDetail.netHourCost;
    const hoursAmount = this.request.requestDetail.hoursAmount;
    //const costoOrarioTotale = this.utilities.computeTotalPrice(province, moreThanOne, handicap, badWorkHours, netHourCost).toFixed(2);
    let costoPacchettoOre = 0;
    const costoOrarioNetto = !(this.request.requestDetail.flatCost !== null && this.request.requestDetail.flatCost > 0) ? this.utilities.computeNetPrice(province, moreThanOne, handicap, badWorkHours, netHourCost).toFixed(2) + '&euro;/ora' : this.utilities.computeFlatPrice(flatCost, flatCostTiming);
    if ((this.request.requestPersonalInfo.customer as CustomerType) === CustomerType.B2B && this.request.requestDetail.packageFlatCost) {
      costoPacchettoOre = this.request.requestDetail.packageFlatCost ?? 0;
    } else if (this.request.requestDetail.flatCost !== null && this.request.requestDetail.flatCost > 0) {
      switch (flatCostTiming) {
        case 2:
          costoPacchettoOre = flatCost * 1.82 * hoursAmount;
          break;
        case 3:
          costoPacchettoOre = flatCost;
          break;
        case 0:
        case 1:
        case 4:
        case 5:
        default:
          costoPacchettoOre = flatCost * 1.82;
          break;
      }
    } else {
      costoPacchettoOre = this.utilities.computeHoursPackagePrice(province, moreThanOne, handicap, badWorkHours, hoursAmount, netHourCost);
    }

    this.dictionary = {
      '${operator}': nomeOperatore,
      '${parent}': nomeGenitore,
      '${hourWage}': costoOrarioNetto,
      '${contactList}': babysitterChosen,
      '${textChosen}': textChosen,
      '${textSelected}': textSelected,
      '${price}': this.dictionary ? this.dictionary['${price}'] : 0,
      '${service_start}': this.dictionary ? this.dictionary['${service_start}'] : 0,
      '${copertura}': this.dictionary ? this.dictionary['${copertura}'] : 0,
      '${copertura_daily}': this.dictionary ? this.dictionary['${copertura_daily}'] : 0,
      '${old_price}': 0,
      '${discount}': 0,
      '${pluralChosen}': pluralChosen,
      '${pluralSelected}': pluralSelected,
      '${phone}': this.selectTutorPhoneFromName(this.request.requestPersonalInfo.operator).phone,
      '${hours_amount}': this.request.requestDetail.hoursAmount,
      '${hours_price}': costoPacchettoOre.toFixed(2),
      '${hours_payment_url}': this.dictionary ? this.dictionary['${hours_payment_url}'] : '',
      '${paymentURL}': this.dictionary ? this.dictionary['${paymentURL}'] : '',
      '${nBabysitter}': nBabysitter,
      '${caregiverName}': caregiverName,
      '${fuel_refund}': this.request.requestDetail.taxisitter ? ' + rimborso spese benzina' : '',
      '${courseURL}': '<a href="https://www.youtube.com/watch?v=uYn_9rffY1Y">https://www.youtube.com/watch?v=uYn_9rffY1Y</a>',
      '${cancelURL}': '<a href="https://babysitter.familyhappy.it/login-genitore">questa pagina</a>',
      '${clientServiceMail}': ` <a href="${clientServiceMail}">${clientServiceMail}</a>`,
      '${requestID}': this.request.id
    }
  }

  private computeScript() {
    if (this.request.requestPersonalInfo.serviceLinked) {
      this.script.next(this.processMessage(this.request.requestPersonalInfo.serviceLinked.script));
    }
  }

  computeTemplates() {
    if (this.templates) {
      const processedTemplates: MailTemplate[] = [];
      this.templates.forEach(template => {
        processedTemplates.push({
          ...template,
          subject: this.processMessage(template.subject),
          text: this.processMessage(template.text)
        })
      })
      this.messagesTemplateObservable.next(processedTemplates);
    }
  }

  getProcessedTemplates() {
    this.updateServiceOverview(this.request.requestPersonalInfo.serviceLinked.id, this.request.requestPersonalInfo.provincia);
    return this.messagesTemplateObservable.asObservable();
  }

  async updateTemplates() {
    await this.updateCaregiverAttachments();
    // await this.updateCaregiverPhoneNumbers();
    this.computeDictionary().then(() => {
      this.computeScript();
      this.computeTemplates();
    });
    if (this.templates.length === 0 && this.request.requestPersonalInfo.serviceLinked) {
      this.mailTemplateService.getAllTemplates(0, 5, { serviceID: this.request.requestPersonalInfo.serviceLinked.id, type: null }).subscribe((templatePage) => {
        this.templates = templatePage.content;
      })
    }
  }
  //OLD UPDATE PAYMENT URL METHOD
  // updatePaymentURL() {
  //   if (this.paymentURL === '' && this.dictionary && this.request.id) {
  //     const personalInfo = this.request.requestPersonalInfo;
  //     const isPacchettoOre = personalInfo.serviceLinked.name.toLocaleLowerCase() === 'Pacchetto Ore'.toLocaleLowerCase();
  //     const isB2BService = (this.request.requestPersonalInfo.customer as CustomerType) === CustomerType.B2B;

  //     // Construct create Payment Link body URL
  //     const body: RequestPaymentBody = {
  //       amount: (isPacchettoOre || isB2BService) ? this.dictionary['${hours_price}'] * 100 : 0,
  //       email: personalInfo.parentEmail,
  //       name: personalInfo.parentName,
  //       phone: personalInfo.parentPhone,
  //       provinceCode: personalInfo.provincia,
  //       seller: personalInfo.operator,
  //       service: personalInfo.serviceLinked.id,
  //       surname: personalInfo.parentSurname,
  //       urlRelated: getURLRelated('REQUEST', `${this.request.id}`)
  //     }

  //     this.paymentService.createPaymentLink(body)
  //       .pipe(takeUntil(this.unsubscribe))
  //       .subscribe({
  //         next: (response) => {
  //           this.paymentURL = response.paymentURL;
  //           // Assign to the right variable the link
  //           if (isPacchettoOre || isB2BService) {
  //             this.dictionary['${hours_payment_url}'] = `<a href="${this.paymentURL}">${this.paymentURL}</a>`;
  //           } else {
  //             this.dictionary['${paymentURL}'] = `<a href="${this.paymentURL}">${this.paymentURL}</a>`;
  //           }
  //           this.updateTemplates();
  //         }
  //       });
  //     return this.paymentURL
  //   } else {
  //     return this.paymentURL
  //   }
  // }


  //NEW UPDATE PAYMENT URL METHOD => RETURN OBSERVABLE<STRING> INSTEAD OF STRING
  updatePaymentUrl() {
    if (this.paymentURL === '' && this.dictionary && this.request.id) {
      const personalInfo = this.request.requestPersonalInfo;
      const isPacchettoOre = personalInfo.serviceLinked.name.toLocaleLowerCase() === 'Pacchetto Ore'.toLocaleLowerCase();
      const isB2BService = (this.request.requestPersonalInfo.customer as CustomerType) === CustomerType.B2B;

      // Construct create Payment Link body URL
      const body: RequestPaymentBody = {
        amount: (isPacchettoOre || isB2BService) ? this.dictionary['${hours_price}'] * 100 : 0,
        email: personalInfo.parentEmail,
        name: personalInfo.parentName,
        phone: personalInfo.parentPhone,
        provinceCode: personalInfo.provincia,
        seller: personalInfo.operator,
        service: personalInfo.serviceLinked.id,
        surname: personalInfo.parentSurname,
        urlRelated: getURLRelated('REQUEST', `${this.request.id}`)
      }

      return this.paymentService.createPaymentLink(body)
        .pipe(
          takeUntil(this.unsubscribe),
          map((response) => {
            this.paymentURL = response.paymentURL;
            if (isPacchettoOre || isB2BService) {
              this.dictionary['${hours_payment_url}'] = `<a href="${this.paymentURL}">${this.paymentURL}</a>`;
            } else {
              this.dictionary['${paymentURL}'] = `<a href="${this.paymentURL}">${this.paymentURL}</a>`;
            }
            this.updateTemplates();
            return this.paymentURL;
          }),
        );
    }
    else {
      return of(this.paymentURL);
    }
  }
  askCaregiverUpdate() {
    this.caregiverSelectionService.getCaregiverByRequestID(this.request.id, this.request.requestPersonalInfo.latitude, this.request.requestPersonalInfo.longitude).subscribe();
  }

  async updateCaregiverPhoneNumbers() {
    let text = ''
    let attachments: CaregiverChosenAttachment[] = [];
    if (this.caregiverChosen) {
      this.caregiverChosen.forEach((elem) => {
        let name = '';
        let surname = '';
        let phone = '';
        let docRef = ''
        if (this.request.requestPersonalInfo.requestCategory === RequestCategory.BABYSITTER) {
          name = elem.babysitter?.info.name ?? '';
          surname = elem.babysitter?.info.surname ?? '';
          phone = elem.babysitter?.info.phone ?? '';
          docRef = elem.babysitter?.docRef ?? '';
        } else if (this.request.requestPersonalInfo.requestCategory === RequestCategory.PETSITTER) {
          name = elem.petsitter?.info.name ?? '';
          surname = elem.petsitter?.info.surname ?? '';
          phone = elem.petsitter?.info.phone ?? '';
          docRef = elem.petsitter?.docRef ?? '';
        } else if (this.request.requestPersonalInfo.requestCategory === RequestCategory.NURSE) {
          name = elem.nurse?.info.name ?? '';
          surname = elem.nurse?.info.surname ?? '';
          phone = elem.nurse?.info.phone ?? '';
          docRef = elem.nurse?.docRef ?? '';
        } else if (this.request.requestPersonalInfo.requestCategory === RequestCategory.COLF) {
          name = elem.colf?.info.name ?? '';
          surname = elem.colf?.info.surname ?? '';
          phone = elem.colf?.info.phone ?? '';
          docRef = elem.colf?.docRef ?? '';
        }
        let caregiverAttachment = {
          phoneText: `<p>${name} ${surname}: ${phone}</p>`,
          docRef: docRef
        }
        attachments.push(caregiverAttachment)
        text += `<p>${name} ${surname}: ${phone}</p>`;
      })
    }
    this.phoneNumbers.next(attachments);
    this.phoneText.next(text)
  }

  getCaregiverPhoneNumbers() {
    return this.phoneNumbers.asObservable();
  }

  async updateCaregiverAttachments() {
    let attachments: CaregiverAttachment[] = [];
    this.caregiverSelected.forEach((elem) => {
      let name = '';
      let surname = '';
      let docRef = '';
      let cv = '';
      if (this.request.requestPersonalInfo.requestCategory === RequestCategory.BABYSITTER) {
        name = elem.babysitter?.info.name ?? '';
        surname = elem.babysitter?.info.surname ?? '';
        docRef = elem.babysitter?.docRef ?? '';
        cv = elem.babysitter?.info.resumeHidden ?? '';
      } else if (this.request.requestPersonalInfo.requestCategory === RequestCategory.PETSITTER) {
        name = elem.petsitter?.info.name ?? '';
        surname = elem.petsitter?.info.surname ?? '';
        docRef = elem.petsitter?.docRef ?? '';
        cv = elem.petsitter?.info.resumeHidden ?? '';
      } else if (this.request.requestPersonalInfo.requestCategory === RequestCategory.NURSE) {
        name = elem.nurse?.info.name ?? '';
        surname = elem.nurse?.info.surname ?? '';
        docRef = elem.nurse?.docRef ?? '';
        cv = elem.nurse?.info.resumeHidden ?? '';
      } else if (this.request.requestPersonalInfo.requestCategory === RequestCategory.COLF) {
        name = elem.colf?.info.name ?? '';
        surname = elem.colf?.info.surname ?? '';
        docRef = elem.colf?.docRef ?? '';
        cv = elem.colf?.info.resumeHidden ?? '';
      }
      if (cv !== undefined && cv !== '') {
        attachments.push({
          name: name,
          surname: surname,
          filename: name.replace(' ', '_') + '_' + docRef + '_CV.pdf',
          path: cv,
          docRef
        });
      }
    })
    this.attachments.next(attachments);
  }

  getCaregiverAttachments() {
    return this.attachments.asObservable();
  }

  private updateServiceOverview(serviceLinkedID: number, province: string) {
    // Retrieve prices overview for selected service
    if (serviceLinkedID && province) {
      this.servicesService.getServiceOverview(serviceLinkedID, province)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((overview) => {
          this.serviceOverview = overview;
          const price = overview.products.find(item => item.productType === ProductType.SERVICE)?.amount;
          const subscription = overview.products.find(item => item.productType !== ProductType.SERVICE)?.amount;
          if (price && subscription) {
            this.dictionary = {
              ...this.dictionary,
              '${price}': ((price + subscription) / 100).toFixed(2),
              '${service_start}': (price / 100).toFixed(2),
              '${copertura}': (subscription / 100).toFixed(2),
              '${copertura_daily}': (subscription / 3000).toFixed(2),
            };
          }
          this.updateTemplates();
          //this.updatePaymentURL();
        })
    }
  }

  private selectTutorPhoneFromName(name: string) {
    switch (name) {
      case "Giulia Drago":
        return { name: "Giulia", phone: "+393509602687" };
      case "Giulia Campanile":
        return { name: "Giulia", phone: "+393463233515" };
      case "Andrèe":
      case "Andrèe Dametti":
        return { name: "Andrèe", phone: "+393291893599" };
      case "Adele Missere":
      case "Adele":
        return { name: "Adele", phone: "+393455522157" };
      case "Manuela":
      case "Manuela Maffei":
        return { name: "Manuela", phone: "+393452360739" };
      case "Gianluca":
      case "Gianluca Lacerenza":
        return { name: "Gianluca", phone: "+393423495242" };
      case "Sofia":
      case "Sofia Lazzati Corizzo":
        return { name: "Sofia", phone: "+393881458635" };
      default:
        return { name: "Giulia", phone: "+393463233515" };
    }
  }

  getMailServiceByOperator(operator: string, isProduction: boolean) {
    if (!isProduction) {
      return "servizioclienti@familyhappy.it";
    }
    switch (operator) {
      case "Giulia Drago":
        return "giulia@familyhappybenefit.com";
      case "Andrèe":
      case "Andrèe Dametti":
        return "andree@familyhappybenefit.com";
      case "Adele Missere":
      case "Adele":
        return "adele@familyhappybenefit.com";
      case "Manuela":
      case "Manuela Maffei":
        return "manuela@familyhappybenefit.com";
      case "Gianluca":
      case "Gianluca Lacerenza":
        return "gianluca@familyhappybenefit.com";
      case "Sofia":
      case "Sofia Lazzati Corizzo":
        return "sofia@familyhappybenefit.com";
      case "Giulia Campanile":
      default:
        return "servizioclienti@familyhappybenefit.com";
    }
  }
  //convert filters to object
  translateObjectFilters(obj: any, translations: any): any {
    let translatedObject: any = {};
    for (let key in obj) {
      // Se il valore è un oggetto annidato, esegui la ricorsione
      if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
        let nestedKey = key
        translatedObject[nestedKey] = this.translateObjectFilters(obj[key], translations[key]);
      } else {
        translatedObject[translations[key] || key] = obj[key];
      }
    }
    let cleanObj = this.cleanObjFitlers(translatedObject)
    return cleanObj;
  }

  cleanObjFitlers(obj: any) {
    const cleanedObj: any = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];

        if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
          // Ricorsione sugli oggetti annidati
          const nestedCleaned = this.cleanObjFitlers(value);
          if (Object.keys(nestedCleaned).length > 0) {
            cleanedObj[key] = nestedCleaned;
          }
        } else if (value !== false && value !== null) {
          // Aggiungiamo solo i valori che non sono `null` o `false`
          cleanedObj[key] = value;
        }
      }
    }

    return cleanedObj;
  }
}
