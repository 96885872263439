import { PetsitterState } from '../../../shared/services/router-state-manager/router-state-manager.service';
import { RouterStateManagerService } from '../../../shared/services/router-state-manager/router-state-manager.service';
import { CaregiverSummary, Petsitter, ModalService } from 'npx-family-happy-common';
import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { PetsitterService } from '../petsitter-service/petsitter-service.service';
import { BehaviorSubject, catchError, combineLatest, debounceTime, Subject, Subscription, takeUntil } from 'rxjs';
import { ProvinceService } from 'src/app/shared/services/province-service/province.service';
import { AuthService } from 'src/app/authentication/auth/auth.service';
import { GoogleMapsService } from 'src/app/requests-handling/caregiver-scoreboard/caregiver-selection/caregiver-selection-service/google-maps.service';
import { CaregiverSelectionService } from 'src/app/requests-handling/caregiver-scoreboard/caregiver-selection/caregiver-selection-service/caregiver-selection.service';
import { ModalDismissReasons, NgbModal, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CaregiverSelectionRecordSortable } from 'src/app/shared/models/caregiverSelectionRecordSortable.models';
import { CommonModule } from '@angular/common';
import { InputMapComponent } from 'src/app/shared/components/input-map/input-map.component';
import { MapComponent } from 'src/app/shared/components/map/map/map.component';
import { RouterModule } from '@angular/router';
import { AlertComponent } from 'src/app/shared/components/modals/alert/alert.component';
import { PetsitterFilterModalComponent } from '../petsitter-filter-modal/petsitter-filter-modal.component';
import { ToastComponent } from 'src/app/shared/components/toast/toast.component';
import { ToastService } from 'src/app/shared/services/toast-service/toast.service';

@Component({
  selector: 'app-petsitter-list',
  templateUrl: './petsitter-list.component.html',
  styleUrls: ['./petsitter-list.component.scss'],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, InputMapComponent, MapComponent, RouterModule, NgbTooltipModule, AlertComponent, PetsitterFilterModalComponent, ToastComponent]
})
export class PetsitterListComponent implements OnInit, OnDestroy, AfterViewInit {

  toast!: ToastComponent | null;

  showAlert = false;
  showMore = false;
  showFilters = false;
  selectedRef = '';
  elementsPerPage = new UntypedFormControl(10);
  showPageButton = true;
  minAge!: number;
  maxAge!: number;
  form = new UntypedFormGroup({
    docRef: new UntypedFormControl(''),
    name: new UntypedFormControl(''),
    email: new UntypedFormControl(''),
    phone: new UntypedFormControl(''),
    fromAge: new UntypedFormControl(null),
    toAge: new UntypedFormControl(null),
    page: new UntypedFormControl(1),
    latitude: new UntypedFormControl(null),
    longitude: new UntypedFormControl(null),
    range: new UntypedFormControl(5)
    /* limit: new FormControl(10) */
  });
  filters: any = {}; //filters of Petsitter filter modal
  filtersObserver = new BehaviorSubject<any>(undefined);
  filtersRecreated = false;
  resetFiltersModal = false;
  petsitters: CaregiverSummary[] = [];
  original: CaregiverSummary[] = [];
  private province = '';
  private subscription!: Subscription;
  private petsitterState!: PetsitterState;
  private subscriptions: Subscription[] = [];
  isAdmin = false;
  unsubscribe = new Subject<void>();
  private closeResult = ""

  //MAPS FILTERS
  sortablePET: CaregiverSelectionRecordSortable[] = []
  address!: any
  addressInput = new UntypedFormControl('')
  resetChildren = false

  constructor(private petsitterService: PetsitterService,
    private provinceService: ProvinceService,
    private stateManager: RouterStateManagerService,
    private modalService: ModalService,
    private authService: AuthService,
    private googleMapsService: GoogleMapsService,
    private caregiverSelectionService: CaregiverSelectionService,
    private ngbModalService: NgbModal,
    private toastService: ToastService
  ) {
    this.authService.getToken().subscribe((token) => this.isAdmin = this.authService.isUserAdmin(token) ?? false);
    this.toastService.getChildComponent().pipe(takeUntil(this.unsubscribe)).subscribe((component) => this.toast = component)
  }

  ngOnInit(): void {
    let subscriptions: any = {};
    subscriptions.province = this.provinceService.getProvince().pipe(catchError((error) => { return error; }));
    subscriptions.state = this.stateManager.getPetsitterState().pipe(catchError((error) => { return error; }));
    this.subscriptions.push(combineLatest(subscriptions).subscribe((values: any) => {
      this.province = values.province;
      this.petsitterState = values.state;
      if (!this.petsitterState.filters.province) {
        this.petsitterState.filters.province = '';
      }
      this.petsitterState.filters.page = 1;
      if (Object.keys(this.petsitterState.filters).length > 0) {
        if (this.province !== this.petsitterState.filters.province) {
          this.filters = this.recreateFilterObject(this.petsitterState.filters, false);
        } else {
          this.filters = this.recreateFilterObject(this.petsitterState.filters, true);
        }
        this.petsitterState.filters.province;
        this.filtersObserver.next(this.filters);
        this.form.setValue({
          docRef: this.petsitterState.filters.docRef ?? '',
          name: this.petsitterState.filters.info?.name ?? '',
          email: this.petsitterState.filters.info?.email ?? '',
          phone: this.petsitterState.filters.info?.phone ?? '',
          fromAge: this.petsitterState.filters.fromAge ?? null,
          toAge: this.petsitterState.filters.toAge ?? null,
          latitude: this.petsitterState.filters.latitude ?? null,
          longitude: this.petsitterState.filters.longitude ?? null,
          range: this.petsitterState.filters.range ?? 10,
          page: 1
        }, { emitEvent: true })
        this.addressInput.setValue(this.petsitterState.filters.address ?? "")
      }
      if (Object.keys(this.petsitterState.filters).length === 0 && this.subscription) {
        try {
          this.askMorePetsitters();
        } catch (err) {
          this.modalService.showErrorMessage('Non è stato possibile caricare i nuovi dati aggiornati.');
        }
      }
      if (!this.subscription) {
        if (this.petsitterState.filters.province === '') {
          this.petsitterState.filters.province = null;
        }
        this.subscription = this.petsitterService.getPetsitter(this.petsitterState.filters, this.elementsPerPage.value).subscribe((data) => {
          if (!this.filtersRecreated) {
            // this.convertAddress(data) //SCRIPT --- CONVERTS ADDRESSES INTO COORDINATES
            this.populatePetsitter(data);
          } else {
            this.filtersRecreated = false
          }
        });
        this.subscriptions.push(this.form.valueChanges.pipe(debounceTime(300)).subscribe((value) => {
          if (value.page > this.petsitterState.filters.page ?? 1) {
            try {
              this.askMorePetsitters();
            } catch (err) {
              this.modalService.showErrorMessage('Non è stato possibile caricare i nuovi dati aggiornati.');
            }
          } else {
            this.petsitters = [];
            this.page?.setValue(1, { emitEvent: false });
            try {
              this.askMorePetsitters();
            } catch (err) {
              this.modalService.showErrorMessage('Non è stato possibile caricare i nuovi dati aggiornati.');
            }
          }

        }))
        this.subscriptions.push(this.elementsPerPage.valueChanges.subscribe(value => {
          this.petsitters = [];
          this.petsitterService.getPetsitter(this.petsitterState.filters, value).subscribe();
        }))
      }
    }));
  }

  generateCaregiverListSortable(pet: Petsitter[]) {
    this.sortablePET = []
    pet.forEach((petsitter) => {
      let PETSortable = {
        fittingIndex: 0,
        record: {
          id: 0,
          requestID: 0,
          babysitter: null,
          petsitter: petsitter,
          nurse: null,
          colf: null,
          suitable: false,
          suitableDate: null,
          selected: false,
          selectedDate: null,
          chosen: false,
          chosenDate: null,
          discarded: false,
          discardedDate: null,
          discardedReason: '',
          distance: 0,
          favorite: false,
          cvSent: false,
          cvSentDate: null,
          contactSent: false,
          contactSentDate: null,
        }
      }
      this.sortablePET.push(PETSortable)
    })
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.subscription.unsubscribe();
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
    this.subscriptions.splice(0, this.subscriptions.length);
    this.stateManager.setPetsitterState(this.petsitterState, false);
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngAfterViewInit(): void {
    /* Google Maps */
    // this.loadGoogleMaps(document.getElementById('address') as HTMLInputElement);
  }

  // loadGoogleMaps(input: HTMLInputElement){
  //   input.addEventListener('change', () => {
  //     input.value = '';
  //     this.form.get('addressInput')?.setValue('', { emitEvent: false });
  //   });
  //   const loader = new Loader({ apiKey: environment.googleMapsAPIKey, version: "weekly", libraries: ["places"], language: 'it-IT' }).load().then((google) => {
  //     const autocomplete = new google.maps.places.Autocomplete(input);
  //     autocomplete.addListener('place_changed', () => {
  //       const place = autocomplete.getPlace();
  //       let formAddress = this.form.get('addressInput');

  //       formAddress?.setValue(place.formatted_address);
  //       this.sendCoordinates(place)
  //     })
  //   });
  // }

  rowClick(petsitter: any) {
    if (!petsitter) {
      this.petsitterState.id = 'new';
    } else {
      this.petsitterState.id = petsitter.docRef;
    }
    this.petsitterState.route = 'petsitter-detail/' + this.petsitterState.id;
    this.petsitterState.step = 0;
    this.petsitterState.petsitter = undefined;
    this.ngOnDestroy();
    this.stateManager.setPetsitterState(this.petsitterState);
  }

  /* Delete petsitter code */
  deleteBabysitter(docRef: string) {
    this.selectedRef = docRef;
    this.showAlert = true;
  }

  onAlertConfimation(event: boolean) {
    if (event) {
      const index = this.petsitters.findIndex(item => item.docRef === this.selectedRef);
      this.petsitters.splice(index, 1);
      this.original.splice(index, 1);
      this.subscriptions.push(this.petsitterService.deletePetsitter(this.selectedRef).subscribe((res: any) => {
        this.modalService.showSuccessMessage('Petsitter eliminata con successo.');
      }));
    }
    this.selectedRef = '';
    this.showAlert = false;
  }

  /* Filter management code */
  showFiltersModal() {
    this.showFilters = true;
    this.filtersObserver.next(this.filters);
  }

  resetFilters() {
    this.petsitters = [];
    this.resetChildren = true
    this.form.reset({
      docRef: '',
      name: '',
      email: '',
      phone: '',
      fromAge: null,
      toAge: null,
      page: 1,
      latitude: null,
      longitude: null,
      range: 10,
    }, { emitEvent: false });
    // Reset petsitter filter modal filters
    this.filters = undefined;
    this.filtersObserver.next(this.filters);
    this.askMorePetsitters(true);
  }

  processFilters(reset = false) {
    this.showFilters = false;
    let filters: any = {};
    if (reset) {
      filters = { page: 1 }; // Load all the petsitters
    } else {
      filters.info = {};
      filters.avail = {};
      filters.exp = {};
      filters.lang = {};
      filters.rating = {};
      filters.engagement = {};
      filters.cert = {};
      filters.page = this.page?.value;
      if (this.docRef?.value !== '') {
        filters.docRef = this.docRef?.value;
      }
      if (this.name?.value !== '') {
        if (filters.info) {
          filters.info.name = this.name?.value;
        }
      }
      if (this.email?.value !== '') {
        if (filters.info) {
          filters.info.email = this.email?.value;
        }
      }
      if (this.phone?.value !== '') {
        if (filters.info) {
          filters.info.phone = this.phone?.value;
        }
      }
      if (this.fromAge?.value || this.toAge?.value) {
        filters.fromAge = this.fromAge?.value,
          filters.toAge = this.toAge?.value
      };
      if (this.latitude !== null) {
        filters.latitude = this.latitude.value
      }
      if (this.longitude !== null) {
        filters.longitude = this.longitude.value
      }
      if (this.range !== null) {
        filters.range = this.range.value
      }
      if (this.addressInput !== null) {
        filters.address = this.addressInput.value
      }

      if (this.filters) {
        const catHealing = this.petsitterService.fromAvailObjectToString(this.filters?.catHealing, 'catHealing');
        if (catHealing !== '') {
          filters.avail.catHealing = catHealing;
        }
        const catHosting = this.petsitterService.fromAvailObjectToString(this.filters?.catHosting, 'catHosting');
        if (catHosting !== '') {
          filters.avail.catHosting = catHosting;
        }
        const dogHealing = this.petsitterService.fromAvailObjectToString(this.filters?.dogHealing, 'dogHealing');
        if (dogHealing !== '') {
          filters.avail.dogHealing = dogHealing;
        }
        const dogHosting = this.petsitterService.fromAvailObjectToString(this.filters?.dogHosting, 'dogHosting');
        if (dogHosting !== '') {
          filters.avail.dogHosting = dogHosting;
        }
        if (this.filters.disability) {
          filters.avail.disability = true;
        }
        if (this.filters.disability) {
          filters.avail.disability = true;
        }
        if (this.filters.taxi) {
          filters.avail.taxi = true;
        }
        if (this.filters.licence) {
          filters.avail.licence = true;
        }
        if (this.filters.car) {
          filters.avail.car = true;
        }
        if (this.filters.homeService) {
          filters.avail.homeService = true;
        }
        if (this.filters.hosting) {
          filters.avail.hosting = true;
        }
        if (this.filters.drugs) {
          filters.avail.drugs = true;
        }
        if (this.filters.illness) {
          filters.avail.illness = true;
        }
        if (this.filters.nightShift) {
          filters.avail.nightShift = true;
        }
        if (this.filters.pension) {
          filters.avail.pension = true;
        }
        if (this.filters.walking) {
          filters.avail.walking = true;
        }
        if (this.filters.fhsub) {
          filters.info.fhsub = true;
          filters.info.fhSubManual = true;
        }
        if (this.filters.evaluation) {
          filters.rating.overall = true;
        }
        const days = this.petsitterService.fromAvailObjectToString(this.filters?.days, 'days');
        if (days !== '') {
          filters.avail.days = days;
        }
        const hours = this.petsitterService.fromAvailObjectToString(this.filters?.hours, 'hours');
        if (hours !== '') {
          filters.avail.hours = hours;
        }
        const contract = this.petsitterService.fromAvailObjectToString(this.filters?.contract, 'contract');
        if (contract !== '') {
          filters.avail.contract = contract;
        }
        if (this.filters.exp) {
          if (this.filters.exp.cluster1) {
            filters.exp.cluster1 = true;
          }
          if (this.filters.exp.cluster2) {
            filters.exp.cluster2 = true;
          }
          if (this.filters.exp.cluster3) {
            filters.exp.cluster3 = true;
          }
        }
      }
    }
    if (this.province !== '') {
      if (!filters || Object.keys(filters).length === 0) {
        filters = { province: this.province };
      } else {
        filters.province = this.province;
      }
    }
    return filters;
  }

  recreateFilterObject(filters: any, fromRequest: boolean) {
    if (filters) {
      if (fromRequest) {
        this.filtersRecreated = true;
      }
      return {
        catHealing: this.petsitterService.fromStringToAvailObject(filters.avail ? (filters.avail.catHealing ? filters.avail.catHealing : '') : '', 'catHealing'),
        catHosting: this.petsitterService.fromStringToAvailObject(filters.avail ? (filters.avail.catHosting ? filters.avail.catHosting : '') : '', 'catHosting'),
        dogHealing: this.petsitterService.fromStringToAvailObject(filters.avail ? (filters.avail.dogHealing ? filters.avail.dogHealing : '') : '', 'dogHealing'),
        dogHosting: this.petsitterService.fromStringToAvailObject(filters.avail ? (filters.avail.dogHosting ? filters.avail.dogHosting : '') : '', 'dogHosting'),
        disability: filters.avail ? filters.avail.disability === true : false,
        drugs: filters.avail ? filters.avail.drugs === true : false,
        homeService: filters.avail ? filters.avail.homeService === true : false,
        hosting: filters.avail ? filters.avail.hosting === true : false,
        illness: filters.avail ? filters.avail.illness === true : false,
        nightShift: filters.avail ? filters.avail.nightShift === true : false,
        pension: filters.avail ? filters.avail.pension === true : false,
        walking: filters.avail ? filters.avail.walking === true : false,
        page: filters.page ?? 1,
        minAge: this.fromAge?.value ?? null,
        maxAge: this.toAge?.value ?? null,
        fhsub: filters.info ? filters.info.fhsub === true : false,
        days: this.petsitterService.fromStringToAvailObject(filters.avail ? (filters.avail.days ? filters.avail.days : '') : '', 'days'),
        hours: this.petsitterService.fromStringToAvailObject(filters.avail ? (filters.avail.hours ? filters.avail.hours : '') : '', 'hours'),
        contract: this.petsitterService.fromStringToAvailObject(filters.avail ? (filters.avail.contract ? filters.avail.contract : '') : '', 'contract'),
        exp: {
          cluster1: filters.exp ? filters.exp.cluster1 === true : false,
          cluster2: filters.exp ? filters.exp.cluster2 === true : false,
          cluster3: filters.exp ? filters.exp.cluster3 === true : false,
        },
      };
    }
    return undefined;
  }

  askMorePetsitters(resetFilters = false) {
    try {
      const filters = this.processFilters(resetFilters);
      this.petsitterState.filters = filters;
      this.petsitterService.getPetsitter(filters, this.elementsPerPage.value).subscribe(petsitters => {
        if (petsitters.length < this.elementsPerPage.value) {
          this.showPageButton = false;
        } else {
          this.showPageButton = true;
        }
      });
    } catch (err) {
      this.modalService.showErrorMessage('Non è stato possibile caricare i nuovi dati aggiornati.');
    }
  }

  get docRef() {
    return this.form.get('docRef');
  }

  get name() {
    return this.form.get('name');
  }

  get email() {
    return this.form.get('email');
  }

  get phone() {
    return this.form.get('phone');
  }

  get fromAge() {
    return this.form.get('fromAge');
  }

  get toAge() {
    return this.form.get('toAge');
  }

  get page() {
    return this.form.get('page');
  }

  get latitude() {
    return this.form.get('latitude')
  }

  get longitude() {
    return this.form.get('longitude')
  }

  get range() {
    return this.form.get('range')
  }

  private populatePetsitter(array: Petsitter[]) {
    if (array.length > 0) {
      this.generateCaregiverListSortable(array)
      this.original = array.map((item) => {
        return {
          name: item.info ? item.info.name : '-',
          surname: item.info ? item.info.surname : '-',
          email: item.info ? item.info.email : '-',
          phone: item.info ? item.info.phone : '-',
          docRef: item.docRef,
          cv: item.info.resumeHidden ?? '-',
          video: item.info.video,
          dangerous: item.rating.dangerous,
          creationDate: new Date(),
          rating: item.rating.overall,
          subscription: item.info.subscription,
          manualSubscription: item.info.manualSubscription,
          certificate: item.certificate
        }
      });
    } else {
      this.generateCaregiverListSortable(array)
      this.original = []
    }
    //SET COORDINATES PERSISTENCE
    let mapInfo = {
      latitude: this.petsitterState.filters.latitude,
      longitude: this.petsitterState.filters.longitude,
      range: this.petsitterState.filters.range,
      addressName: this.petsitterState.filters.address,
      caregiverList: this.sortablePET
    }
    this.googleMapsService.setCoordinates(mapInfo)
    this.petsitters.push(...this.original);
  }

  loadMore() {
    this.page?.setValue(this.page.value + 1);
  }

  exportPetsitters() {
    const requestFilters = this.processFilters();
    try {
      this.petsitterService.exportPetsittersFromDB(requestFilters).subscribe((blob) => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'petsitters.xlsx';
        link.click();
      });
    } catch (err) {
      this.modalService.showErrorMessage(`Non è stato possibile completare l'export dei dati, perché mancano non è stato possibile applicare i filtri.`);
    }
  }

  copyToClipboard(event: any) {
    navigator.clipboard.writeText(event);
    this.toastService.setAction('Dati copiati con successo! Ora puoi incollarli dove vuoi.')
    this.toast!.showSuccess(this.toast!.successTpl)
  }

  //SCRIPT --- CONVERT ADDRESSES INTO COORDINATES
  private async convertAddress(array: Petsitter[]) {
    let counterTotal = 0
    let counterConverted = 0
    let counterNull = 0
    for (let i = 0; i < array.length; i++) {
      counterTotal++
      const BB = array[i];
      const address = BB.info.address
      const latitude = BB.info.latitude
      const longitude = BB.info.longitude
      if (address !== null && address !== '') {
        if (latitude === null && longitude === null) {
          try {
            const res = await this.googleMapsService.getCoordinates(address);
            const lat = res[0].geometry.location.lat();
            const lng = res[0].geometry.location.lng();

            const updatedPET = {
              ...BB,
              info: {
                ...BB.info,
                latitude: lat,
                longitude: lng
              }
            };
            await this.petsitterService.updatePetsitter(updatedPET).pipe(takeUntil(this.unsubscribe)).toPromise();
            counterConverted++

            // delay for each interaction
            await this.delay(1000);
          } catch (err) {
            console.log(err);
          }
        } else {
          console.log("ALREADY COORDINATES", longitude, latitude)
          counterNull++
        }
      } else {
        console.log("UPDATED BB ADDRESS NULL", address);
        counterNull++
      }
    }
    console.log(`conversioni totali: ${counterTotal}`)
    console.log(`conversioni fallite (o già con coordinate): ${counterNull}`)
    console.log(`conversioni riuscite: ${counterConverted}`)
  }

  async sendCoordinates(address: any) {
    try {
      if (address !== null && address !== '') {
        const res = await this.googleMapsService.getCoordinates(address)
        let coordinates = {
          lat: res[0].geometry.location.lat(),
          lng: res[0].geometry.location.lng(),
        }
        this.form.get('latitude')?.setValue(coordinates.lat)
        this.form.get('longitude')?.setValue(coordinates.lng)
        this.address = {
          latitude: this.form.get('latitude')?.value,
          longitude: this.form.get('longitude')?.value,
          range: this.form.get('range')?.value,
        }
      } else {
        console.log('insert address')
      }
    } catch {
      console.log("error")
    }
  }

  private delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  //MAPS
  open(content: any) {
    this.ngbModalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      },
    );
  }

  private getDismissReason(reason: any): string {
    switch (reason) {
      case ModalDismissReasons.ESC:
        return 'by pressing ESC';
      case ModalDismissReasons.BACKDROP_CLICK:
        return 'by clicking on a backdrop';
      default:
        return `with: ${reason}`;
    }
  }
  sendAddress(event: any) {
    this.resetChildren = false
    this.addressInput.setValue(event)
    this.sendCoordinates(event)
  }
}
