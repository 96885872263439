import { environment } from 'src/environments/environment';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { HttpClient } from '@angular/common/http';
import { Nurse } from 'npx-family-happy-common';
import { Injectable } from '@angular/core';
import { map, Observable, BehaviorSubject, forkJoin, catchError } from 'rxjs';


const DEFAULT_FILTERS = {
  age_lower: null,
  age_upper: null,
  cats: false,
  cluster1: false,
  cluster2: false,
  cluster3: false,
  contract: null,
  days: null,
  disability: false,
  doc_ref: null,
  dogs: false,
  dogs1: false,
  dogs2: false,
  dogs3: false,
  dogs4: false,
  drugs: false,
  email: null,
  fhsub: false,
  homeService: false,
  hosting: false,
  hours: null,
  illness: false,
  limit: 10,
  name: null,
  nightShift: false,
  page: 0,
  pension: false,
  phone: null,
  province: null,
  walking: false
}

@Injectable({
  providedIn: 'root'
})
export class NurseService {
  baseURL = environment.nurseServiceURL;
  nurseList = new BehaviorSubject<Nurse[]>([]);

  constructor(private http: HttpClient, private firestore: AngularFirestore) {
  }

  checkEmptyField(field: string) {
    return field !== '' ? field : 'unknown';
  }

  getFiveSuggestions(search: string, province: string): Observable<Nurse[]> {
    const filters = {
      ...DEFAULT_FILTERS,
      limit: 5,
      name: search ? search.replace(`'`, `''`) : '',
      province: province !== '' ? province : null
    };

    return this.http.post<Nurse[]>(this.baseURL + '/find', filters).pipe(map((nurseList) => {
      return nurseList.filter(nurse => nurse.rating.dangerous === false).slice(0, 5);
    }));
  }

  getNurse(filters: any, limit: number): Observable<Nurse[]> {
    const request = this.getNurseFilters(filters, limit);

    if (request) {
      this.http.post<Nurse[]>(this.baseURL + '/find', request).subscribe((data) => {
        this.nurseList.next(data);
      });
    }

    return this.nurseList;
  }

  getWhatsappFilteredTargets(filters: any, latitude: number, longitude: number, range: number | null = 5, limit = 20): any {
    if (filters) {

      const request = {
        age_lower: filters.fromAge ?? null,
        age_upper: filters.toAge ?? null,
        car: filters.car ?? false,
        changing: filters.changing ?? false,
        cleaning: filters.cleaning ?? false,
        cluster1: filters.exp.cluster1 ?? false,
        cluster2: filters.exp.cluster2 ?? false,
        contract: filters.contract === '' ? null : (filters.contract ?? null),
        days: filters.days === '' ? null : (filters.days ?? null),
        dayShift: filters.dayShift ?? false,
        doc_ref: filters.docRef ?? null,
        dressings: filters.specialities ? filters.specialities.dressings : false,
        email: filters.email ?? null,
        english: filters.lang.english ?? false,
        fhsub: filters.fhsub ?? false,
        glycemia: filters.specialities ? filters.specialities.glycemia : false,
        hospital: filters.hospital ?? false,
        hours: filters.hours === '' ? null : (filters.hours ?? null),
        injections: filters.specialities ? filters.specialities.injections : false,
        italian: filters.lang.italian ?? false,
        license: filters.license ?? false,
        limit,
        name: null,
        nightShift: filters.nightShift ?? false,
        page: filters.page ?? 1,
        phone: filters.phone ?? null,
        province: filters.province ?? null,
        rating: filters.overall ?? false,
        spanish: filters.lang.spanish ?? false,
        taxi: filters.taxi ?? false,
        taxiNoCar: filters.taxiNoCar ?? false,
        technology: filters.specialities ? filters.specialities.technology : false,
        walking: filters.walking ?? false,
        range: range ?? null,
        latitude: latitude ?? null,
        longitude: longitude ?? null
      }
      return this.http.post<any>(this.baseURL + '/get-whatsapp-filtered-target', request)
    }
  }

  getListOfNurses(ids: string[]) {
    let nurses: any = {};
    for (let i = 0; i < ids.length; i++) {
      const id = ids[i];
      nurses[id] = this.getSingleNurse(id).pipe(catchError((error) => { return error }));
    }
    return forkJoin(nurses);
  }

  getSingleNurse(id: string) {
    const filters = {
      ...DEFAULT_FILTERS,
      doc_ref: id,
      limit: 1
    };

    return this.http.post<Nurse[]>(this.baseURL + '/find', filters);
  }

  getOneBabysitter(id: string) {
    const filters = {
      ...DEFAULT_FILTERS,
      doc_ref: id,
      limit: 1
    }

    return this.http.post<Nurse[]>(this.baseURL + '/find', filters).pipe(map((res: Nurse[]) => res.length ? res[0] : null));
  }

  updateNurse(requestData: Nurse) {
    return this.http.post(this.baseURL + '/import', requestData)
  }

  async getFirebaseDocRef(email: string) {
    const doc = await this.firestore.collection('babysitter').add({ placeholder: email });
    return doc.id;
  }

  createNurse(requestData: Nurse) {
    return this.http.post(this.baseURL + '/import', requestData);
  }

  deleteNurse(id: string) {
    // Remember to delete storage data
    return this.http.delete(this.baseURL + '/delete/' + id);
  }

  getWhatsappTargets(province: string, latitude: number | null, longitude: number | null, range: number | null) {
    // if(range !== "Province"){
    //   range = parseInt(range);
    // } else {
    //   range = null
    // }
    let rangeParam = (range === null) ? '' : range;
    return this.http.get<string[]>(`${this.baseURL}/get-whatsapp-target?province=${province}&latitude=${latitude}&longitude=${longitude}&range=${rangeParam}`);
  }

  /* Utilities methods */

  // It converts an object to a string. e.g.: {sun: true, mon: false, tue: true} -> 'sun-tue'
  fromAvailObjectToString(object: any, type: string) {
    let result = '';
    if (object) {
      switch (type) {
        case 'hours':
          result += object['mor'] ? 'mor' : '';
          result += object['aft'] ? (result !== '' ? '-aft' : 'aft') : '';
          result += object['eve'] ? (result !== '' ? '-eve' : 'eve') : '';
          return result;
        case 'days':
          result += object['sun'] ? 'sun' : '';
          result += object['mon'] ? (result !== '' ? '-mon' : 'mon') : '';
          result += object['tue'] ? (result !== '' ? '-tue' : 'tue') : '';
          result += object['wed'] ? (result !== '' ? '-wed' : 'wed') : '';
          result += object['thu'] ? (result !== '' ? '-thu' : 'thu') : '';
          result += object['fri'] ? (result !== '' ? '-fri' : 'fri') : '';
          result += object['sat'] ? (result !== '' ? '-sat' : 'sat') : '';
          return result;
        case 'contract':
          result += object['occ'] ? 'occ' : '';
          result += object['part'] ? (result !== '' ? '-part' : 'part') : '';
          result += object['full'] ? (result !== '' ? '-full' : 'full') : '';
          return result;
        case 'tasks1':
        case 'tasks2':
          result += object['assistance'] ? 'assistance' : '';
          result += object['care'] ? (result !== '' ? '-care' : 'care') : '';
          result += object['drugs'] ? (result !== '' ? '-drugs' : 'drugs') : '';
          result += object['cooking'] ? (result !== '' ? '-cooking' : 'cooking') : '';
          result += object['housing'] ? (result !== '' ? '-housing' : 'housing') : '';
          result += object['other'] ? (result !== '' ? '-other' : 'other') : '';
          return result;
        default:
          return '';
      }
    } else {
      return result;
    }
  }

  // It converts a string to an object (hour, contract or days property of Nurse). e.g.: 'sun-tue' -> {sun: true, mon: false, tue: true}
  fromStringToAvailObject(origin: string, type: string) {
    if (origin) {
      let keys = origin.split('-') || [];
      switch (type) {
        case 'hours':
          return {
            mor: keys.findIndex(item => item === 'mor') !== -1,
            aft: keys.findIndex(item => item === 'aft') !== -1,
            eve: keys.findIndex(item => item === 'eve') !== -1,
          }
        case 'days':
          return {
            sun: keys.findIndex(item => item === 'sun') !== -1,
            mon: keys.findIndex(item => item === 'mon') !== -1,
            tue: keys.findIndex(item => item === 'tue') !== -1,
            wed: keys.findIndex(item => item === 'wed') !== -1,
            thu: keys.findIndex(item => item === 'thu') !== -1,
            fri: keys.findIndex(item => item === 'fri') !== -1,
            sat: keys.findIndex(item => item === 'sat') !== -1,
          };
        case 'contract':
          return {
            occ: keys.findIndex(item => item === 'occ') !== -1,
            part: keys.findIndex(item => item === 'part') !== -1,
            full: keys.findIndex(item => item === 'full') !== -1,
          };
        case 'tasks1':
        case 'tasks2':
          return {
            assistance: keys.findIndex(item => item === 'assistance') !== -1,
            care: keys.findIndex(item => item === 'care') !== -1,
            drugs: keys.findIndex(item => item === 'drugs') !== -1,
            cooking: keys.findIndex(item => item === 'cooking') !== -1,
            housing: keys.findIndex(item => item === 'housing') !== -1,
            other: keys.findIndex(item => item === 'other') !== -1
          }
        default:
          return {
            occ: false,
            part: false,
            full: false,
          };
      }
    } else {
      switch (type) {
        case 'hours':
          return {
            mor: false,
            aft: false,
            eve: false,
          }
        case 'days':
          return {
            sun: false,
            mon: false,
            tue: false,
            wed: false,
            thu: false,
            fri: false,
            sat: false,
          };
        case 'contract':
          return {
            occ: false,
            part: false,
            full: false,
          };
        case 'tasks1':
        case 'tasks2':
          return {
            assistance: false,
            care: false,
            drugs: false,
            cooking: false,
            housing: false,
            other: false
          }
        default:
          return undefined;
      }
    }
  }

  exportNursesFromDB(filters: any) {
    const request = this.getNurseFilters(filters, 50);

    if (request) {
      return this.http.post(this.baseURL + '/export-nurses-to-excel', request, { responseType: 'blob' });
    }

    throw new Error('No filters provided');
  }

  private getNurseFilters(filters: any, limit: number) {
    if (filters) {
      if (!filters.info) {
        filters.info = {};
      }
      if (!filters.avail) {
        filters.avail = {};
      }
      if (!filters.exp) {
        filters.exp = {};
      }
      if (!filters.lang) {
        filters.lang = {};
      }
      if (!filters.rating) {
        filters.rating = {};
      }
      if (!filters.engagement) {
        filters.engagement = {};
      }
      if (!filters.cert) {
        filters.cert = {};
      }
      return {
        age_lower: filters.fromAge ?? null,
        age_upper: filters.toAge ?? null,
        car: filters.avail.car ?? false,
        changing: filters.avail.changing ?? false,
        cleaning: filters.avail.cleaning ?? false,
        cluster1: filters.exp.cluster1 ?? false,
        cluster2: filters.exp.cluster2 ?? false,
        contract: filters.avail.contract ?? null,
        days: filters.avail.days ?? null,
        dayShift: filters.avail.dayShift ?? false,
        doc_ref: filters.docRef ?? null,
        dressings: filters.lang.specialities ? filters.lang.specialities.dressings : false,
        email: filters.info.email ?? null,
        english: filters.lang.english ?? false,
        fhsub: filters.info.fhsub ?? false,
        glycemia: filters.lang.specialities ? filters.lang.specialities.glycemia : false,
        hospital: filters.avail.hospital ?? false,
        hours: filters.avail.hours ?? null,
        injections: filters.lang.specialities ? filters.lang.specialities.injections : false,
        italian: filters.lang.italian ?? false,
        license: filters.avail.license ?? false,
        limit,
        name: filters.info.name ? filters.info.name.replace(`'`, `''`) : null,
        nightShift: filters.avail.nightShift ?? false,
        page: filters.page ?? 1,
        phone: filters.info.phone ?? null,
        province: filters.province ?? null,
        rating: filters.rating.overall ?? false,
        spanish: filters.lang.spanish ?? false,
        taxi: filters.avail.taxi ?? false,
        taxiNoCar: filters.avail.taxiNoCar ?? false,
        technology: filters.lang.specialities ? filters.lang.specialities.technology : false,
        walking: filters.avail.walking ?? false,
        range: filters.range * 1000 ?? null,
        latitude: filters.latitude ?? null,
        longitude: filters.longitude ?? null
      }
    }

    return undefined;
  }
}
