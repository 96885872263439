import { Observable } from 'rxjs';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CaregiverSelectionService } from 'src/app/requests-handling/caregiver-scoreboard/caregiver-selection/caregiver-selection-service/caregiver-selection.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-petsitter-filter-modal',
  templateUrl: './petsitter-filter-modal.component.html',
  styleUrls: ['./petsitter-filter-modal.component.scss'],
  standalone:true,
  imports:[CommonModule, ReactiveFormsModule]
})
export class PetsitterFilterModalComponent implements OnInit {

  private _resetAllFilters: boolean = false;

  @Input() filtersValue!: Observable<any>;
  @Input() showAgeFilter: boolean = false;
  @Output() onClose = new EventEmitter<void>();
  @Output() onFilter = new EventEmitter<any>();
  filters!: UntypedFormGroup;

  // @Input()
  // get resetAllFilters(): boolean {
  //   return this._resetAllFilters;
  // }
  // set resetAllFilters(value: boolean) {
  //   this._resetAllFilters = value;
  //   this.caregiverSelectionService.setFilters(null)
  //   this.resetFilters();
  // }

  constructor(private caregiverSelectionService: CaregiverSelectionService) { }

  ngOnInit(): void {
    this.filters = new UntypedFormGroup({
      catHealing: new UntypedFormGroup({
        cat: new UntypedFormControl(false),
      }),
      catHosting: new UntypedFormGroup({
        cat: new UntypedFormControl(false),
      }),
      dogHealing: new UntypedFormGroup({
        dog: new UntypedFormControl(false),
        dog1: new UntypedFormControl(false),
        dog2: new UntypedFormControl(false),
        dog3: new UntypedFormControl(false),
        dog4: new UntypedFormControl(false),
      }),
      dogHosting: new UntypedFormGroup({
        dog: new UntypedFormControl(false),
        dog1: new UntypedFormControl(false),
        dog2: new UntypedFormControl(false),
        dog3: new UntypedFormControl(false),
        dog4: new UntypedFormControl(false),
      }),
      disability: new UntypedFormControl(false),
      drugs: new UntypedFormControl(false),
      homeService: new UntypedFormControl(false),
      fhsub: new UntypedFormControl(false),
      hosting: new UntypedFormControl(false),
      illness: new UntypedFormControl(false),
      nightShift: new UntypedFormControl(false),
      pension: new UntypedFormControl(false),
      walking: new UntypedFormControl(false),
      minAge: new UntypedFormControl(0),
      maxAge: new UntypedFormControl(0),
      evaluation: new UntypedFormControl(0),
      page: new UntypedFormControl(1),
      distance: new UntypedFormControl(null),
      days: new UntypedFormGroup({
        sun: new UntypedFormControl(false),
        mon: new UntypedFormControl(false),
        tue: new UntypedFormControl(false),
        wed: new UntypedFormControl(false),
        thu: new UntypedFormControl(false),
        fri: new UntypedFormControl(false),
        sat: new UntypedFormControl(false),
      }),
      hours: new UntypedFormGroup({
        mor: new UntypedFormControl(false),
        aft: new UntypedFormControl(false),
        eve: new UntypedFormControl(false),
      }),
      contract: new UntypedFormGroup({
        occ: new UntypedFormControl(false),
        part: new UntypedFormControl(false),
        full: new UntypedFormControl(false),
      }),
      exp: new UntypedFormGroup({
        cluster1: new UntypedFormControl(false),
        cluster2: new UntypedFormControl(false),
        cluster3: new UntypedFormControl(false),
      }),
    });
    this.caregiverSelectionService.getFilters().subscribe((filters) => {
      if (filters !== undefined && filters !== null) {
        if (Object.keys(filters).length > 0) {
          this.filters.setValue(filters);
        } else {
          this.filters.reset();
        }
      }
    });
  }

  /* Close the modal */
  dismiss(event: Event) {
    if (event.target === document.getElementById('modal')) {
      this.onClose.emit();
    }
  }

  emitFilter() {
    this.caregiverSelectionService.setFilters(this.filters.value);
    this.onFilter.emit(this.filters.value);
  }

  resetFilters() {
    if(this.filters !== undefined){
      this.filters.reset();
      this.caregiverSelectionService.setFilters(this.filters.value);
    }
  }

}
